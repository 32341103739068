import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Database from "../utils/database";
import { useParams } from "react-router-dom";
import DefaultSettings from "../defaultSettings/settings";

const VideoPage = () => {
  const { clientID, videoName } = useParams();
  const [url, setUrl] = useState("");
  // console.log("params", params);
  useEffect(() => {
    Database.getClientVideo(clientID, videoName).then(res => setUrl(res));
  }, []);

  return (
    <div
      style={{
        marginTop: "20px",
        width: `100%`,
        height: `90vh`,
        textAlign: "center"
      }}
    >
      <ReactPlayer
        key={"video"}
        url={url}
        preload={true}
        controls={true}
        playing={true}
        playsinline={true}
        volume={DefaultSettings.volume}
        muted={false}
        width={"100%"}
        height={"100%"}
      />
    </div>
  );
};

export default VideoPage;

import React, { useContext, useState, useEffect, useRef } from "react";

import { ItemContext } from "../../ComponentLoader";
import { Grid } from "@material-ui/core";
import AssetDatabase from "../../../../utils/assetDatabase";
import ComponentFullImage from "../../ComponentFullImage";
import DefaultSettings from "../../../../defaultSettings/settings";
import SegementListBlock from "./SegementListBlocks";

// const styles = theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

// const useStyles = makeStyles(theme => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   button: {
//     margin: theme.spacing(1),
//     backgroundColor:'#B9D949'
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 300,
//   },
//   formControl: {
//     margin: theme.spacing(2),
//   },
// }));

export default function SegmentedListItem(props) {
  const itemContext = useContext(ItemContext);
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    TourPlayer,
    openingComponentSelector,
    editMode
  } = itemContext;
  const { advancedSettings } = item.data;
  const {
    index,
    lockColors,
    element,
    listItem,
    listCount,
    setCurrentItem,
    topPercentage,
    barMode,
    mouseDown,
    setMouseDown,
    selectedBlocks,
    setSelectedBlocks,
    onSelectBlock,
    onCheckBlock,
    onDeleteBlock,
    altDown,
    handleSaveScrollPosition
  } = props;

  const getSelectedItem = char => {
    let linkedItem = null;
    siblings.forEach(element => {
      if (
        element.id === (listItem.single ? listItem.link : listItem[char].link)
      ) {
        linkedItem = element;
      }
    });
    return linkedItem;
  };

  console.log("previewMode", previewMode, lockColors);

  const visibleGrid = !listItem.single
    ? ["A", "B", "C", "D"].map((char, i) => {
        return (
          <SegementListBlock
            index={index}
            charIndex={i}
            char={char}
            single={false}
            listItem={listItem}
            mouseDown={mouseDown}
            lockColor={typeof lockColors === "object" ? lockColors[char] : ""}
            onSelectBlock={onSelectBlock}
            selectBlock={onCheckBlock(char)}
            selectedBlocks={selectedBlocks}
            getSelectedItem={getSelectedItem}
            onDeleteBlock={onDeleteBlock}
            altDown={altDown}
          />
        );
      })
    : [
        <SegementListBlock
          index={index}
          charIndex={null}
          char={null}
          single={true}
          listItem={listItem}
          mouseDown={mouseDown}
          lockColor={typeof lockColors === "string" ? lockColors : ""}
          onSelectBlock={onSelectBlock}
          selectedBlocks={selectedBlocks}
          getSelectedItem={getSelectedItem}
          onDeleteBlock={onDeleteBlock}
          altDown={altDown}
        />
      ];

  // const visibleGrid = !listItem.single
  //   ? ["A", "B", "C", "D"].map((char, i) => {
  //     if (!previewMode) {
  //       return (
  //         <div
  //           onClick={() => {

  //          if(!lockColors[char]){
  //           onSelectBlock(char);
  //          }
  //             // clickRef.current = setTimeout(() => {
  //             //   if (onCheckBlock(char)) {
  //             //     onDeleteBlock(char);
  //             //     colors[i] = "";
  //             //     setColors(colors);
  //             //   } else {
  //             //     onDeleteBlock();
  //             //   }
  //             // }, 1000);
  //           }}
  //           onDoubleClick={() => {
  //             let lockBlocks = ""
  //             let listOfBlocks = [];
  //             let isEdit = false;
  //             clearTimeout(clickRef.current);
  //             // console.log("selectedBlocks", selectedBlocks)
  //             setMouseDown(false);
  //             console.log(
  //               "item.advancedSettings.selected",
  //               item.advancedSettings
  //             );

  //             if (lockColors[char]) {
  //               advancedSettings.selected.forEach(item => {
  //                 const blocks = item.split(",");
  //                 blocks.forEach(val => {
  //                   if (val === `${index}_${char}`) {
  //                     isEdit = true;
  //                     listOfBlocks = blocks;
  //                     lockBlocks = item
  //                   }
  //                 });
  //               });
  //             } else if (!onCheckBlock(char)) {
  //               listOfBlocks = [`${index}_${char}`];
  //             } else {
  //               listOfBlocks = selectedBlocks;
  //             }

  //             openingComponentSelector(
  //               {
  //                 button: listItem[char],
  //                 itemSelected: getSelectedItem(char),
  //                 editMode
  //               },
  //               details => {
  //                 const { button, action, selectedID } = details;

  //                 if (listOfBlocks.length > 0) {
  //                   listOfBlocks.forEach(val => {
  //                     const [index, char] = val.split("_");
  //                     // console.log("index, char", index, char);
  //                     const listItem = element[index];

  //                     if (action === "update") {
  //                       listItem[char].link = selectedID;
  //                       listItem[char].backToPrevious = button.backToPrevious;
  //                       listItem[char].exitTour = button.exitTour;
  //                       listItem[char].toKeypad = button.toKeypad;
  //                       listItem[char].returnLink = button.returnLink;

  //                     } else if (action === "remove") {
  //                       listItem[char].link = null;
  //                       listItem[char].backToPrevious = null;
  //                       listItem[char].exitTour = null;
  //                       listItem[char].toKeypad = null;
  //                       listItem[char].returnLink = null;

  //                     }
  //                   });

  //                   if (action === "update" && !isEdit) {
  //                     advancedSettings.selected.push(listOfBlocks.join(","));
  //                     onDeleteBlock();
  //                   }

  //                   if(action === "remove"){
  //                     if (isEdit) {
  //                       advancedSettings.selected = advancedSettings.selected.filter(val => val !== lockBlocks)
  //                     } else {
  //                       onDeleteBlock()
  //                       setColors(["", "", "", ""])
  //                     }
  //                   }

  //                 } else {
  //                   console.log("handleclose:", details);
  //                   const { button, action, selectedID } = details;

  //                   if (action === "update") {
  //                     listItem[char].link = selectedID;
  //                     listItem[char].backToPrevious = button.backToPrevious;
  //                     listItem[char].exitTour = button.exitTour;
  //                     listItem[char].toKeypad = button.toKeypad;
  //                     listItem[char].returnLink = button.returnLink;

  //                     if (!isEdit) {
  //                       advancedSettings.selected.push(listOfBlocks.join(","));
  //                       onDeleteBlock();
  //                     }
  //                   } else if (action === "remove") {
  //                     listItem[char].link = null;
  //                     listItem[char].backToPrevious = null;
  //                     listItem[char].exitTour = null;
  //                     listItem[char].toKeypad = null;
  //                     listItem[char].returnLink = null;
  //                     if (isEdit) {
  //                       advancedSettings.selected = advancedSettings.selected.filter(val => val !== lockBlocks)
  //                     } else {
  //                       onDeleteBlock()
  //                       setColors(["", "", "", ""])
  //                     }

  //                   }
  //                 }

  //                 if (item !== null) {
  //                   updateItem(item, previewMode, "Blocks handle close");
  //                 }

  //                 // if (onSelected) {
  //                 //   onSelected(listItem[char])
  //                 // }
  //               },
  //               previewMode
  //             );
  //           }}
  //           onMouseOver={e => {
  //             // console.log("mouseDown", mouseDown)
  //             const currColor = [...colors];
  //             if (mouseDown && !lockColors[char]) {
  //               onSelectBlock(char);
  //             } else {
  //               currColor[i] = "#00aaff88";
  //               // console.log("onCheckBlock", onCheckBlock(char))
  //               // if (!onCheckBlock(char)) {
  //               //   if (isActive) {
  //               //     currColor[i] = "#ffaa00dd"
  //               //   } else {
  //               //     currColor[i] = "#00aaff88"
  //               //   }
  //               // }
  //             }

  //             setColors(currColor);
  //           }}
  //           onMouseOut={e => {
  //             console.log(
  //               "mouseDown, selectedBlocks",
  //               mouseDown,
  //               selectedBlocks
  //             );
  //             // console.log("target", e.target.innerText)
  //             // console.log("onCheckBlock", onCheckBlock(char))
  //             if (!mouseDown && !onCheckBlock(char)) {
  //               const currColor = [...colors];
  //               currColor[i] = "";
  //               setColors(currColor);
  //             }
  //           }}
  //           style={{
  //             position: "absolute",
  //             left: `${i * 25}%`,
  //             backgroundColor:
  //               lockColors && lockColors[char]
  //                 ? lockColors[char]
  //                 : onCheckBlock(char) ? "#ffaa00dd" : colors[i],
  //             width: "25%",
  //             height: "100%",
  //             border: "1px solid #00ffaaAA",
  //             fontSize: "40px",
  //             color: "white",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //             cursor: "pointer"
  //           }}
  //         >
  //           <p>
  //             {char}
  //           </p>
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div
  //           onClick={() => {
  //             // console.log('listItem', listItem)
  //             let loadItem = null;
  //             // console.log("listItem", listItem)
  //             if (listItem[char].backToPrevious === true) {
  //               loadItem = itemContext.previousItem;
  //             } else if (listItem[char].toKeypad === true) {
  //               loadItem = DefaultSettings.keypadItem;
  //               loadItem.clientId = item.clientId;
  //               loadItem.projectId = item.projectId;
  //               loadItem.tourDetails = item.tourDetails;
  //               loadItem.projectDetails = item.projectDetails;
  //             } else {
  //               siblings.forEach(element => {
  //                 if (element.id === listItem[char].link) {
  //                   loadItem = element;
  //                 }
  //               });
  //             }

  //             console.log("list item clicked", listItem, loadItem);
  //             if (listItem[char].exitTour === true) {
  //               loadItem = "exit";
  //             }
  //             if (listItem[char].externalLinkEnabled === true) {
  //               loadItem = "externalLinkWithoutButton";
  //             }
  //             if (loadItem !== null) {
  //               updateItem(loadItem, previewMode, null, listItem);
  //             }
  //           }}
  //           style={{
  //             cursor: lockColors?.[char] ? "pointer" : "default",
  //             position: "absolute",
  //             left: `${i * 25}%`,
  //             // backgroundColor: colors[i],
  //             width: "25%",
  //             height: "100%",
  //             fontSize: "40px",
  //             color: "white",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center"
  //           }}
  //         />
  //       );
  //     }
  //   })
  //   : [
  //     !previewMode
  //       ? <div
  //         onDoubleClick={() => {

  //           openingComponentSelector(
  //             {
  //               button: listItem,
  //               itemSelected: getSelectedItem(),
  //               editMode
  //             },
  //             details => {
  //               const { button, action, selectedID } = details;

  //               if (action === "update") {
  //                 listItem.link = selectedID;
  //                 listItem.backToPrevious = button.backToPrevious;
  //                 listItem.exitTour = button.exitTour;
  //                 listItem.toKeypad = button.toKeypad;
  //                 listItem.returnLink = button.returnLink;
  //                 if (!(typeof lockColors === 'string')) {
  //                   advancedSettings.selected.push(`${index}`);
  //                   onDeleteBlock();
  //                 }
  //               } else if (action === "remove") {
  //                 listItem.link = null;
  //                 listItem.backToPrevious = null;
  //                 listItem.exitTour = null;
  //                 listItem.toKeypad = null;
  //                 listItem.returnLink = null;
  //                 if (typeof lockColors === 'string') {
  //                   console.log('advancedSettings.selected before', advancedSettings.selected)
  //                   advancedSettings.selected = advancedSettings.selected.filter(val => val !== `${index}`)
  //                   console.log('advancedSettings.selected', advancedSettings.selected)
  //                 } else {
  //                   onDeleteBlock()
  //                   setColors("")
  //                 }
  //               }

  //               if (item !== null) {
  //                 updateItem(item, previewMode, "Blocks handle close");
  //               }
  //             },
  //             previewMode
  //           );
  //         }}
  //         onMouseOver={() => setColors("#00aaff88")}
  //         onMouseOut={() => setColors("")}
  //         style={{
  //           position: "absolute",
  //           // backgroundColor: colors,
  //           backgroundColor: typeof lockColors === 'string' ? lockColors : colors,
  //           width: "100%",
  //           height: "100%",
  //           border: "1px solid #00ffaaAA",
  //           fontSize: "40px",
  //           color: "white",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           cursor: "pointer"
  //         }}
  //       />
  //       : <div
  //         onClick={() => {
  //           let loadItem = null;
  //           if (listItem.backToPrevious === true) {
  //             loadItem = itemContext.previousItem;
  //           } else if (listItem.toKeypad === true) {
  //             loadItem = DefaultSettings.keypadItem;
  //             loadItem.clientId = item.clientId;
  //             loadItem.projectId = item.projectId;
  //             loadItem.tourDetails = item.tourDetails;
  //             loadItem.projectDetails = item.projectDetails;
  //           } else {
  //             siblings.forEach(element => {
  //               if (element.id === listItem.link) {
  //                 loadItem = element;
  //               }
  //             });
  //           }

  //           console.log("list item clicked", listItem, loadItem);
  //           if (listItem.exitTour === true) {
  //             loadItem = "exit";
  //           }
  //           if (listItem.externalLinkEnabled === true) {
  //             loadItem = "externalLinkWithoutButton";
  //           }
  //           if (loadItem !== null) {
  //             updateItem(loadItem, previewMode, null, listItem);
  //           }
  //         }}
  //         style={{
  //           position: "absolute",
  //           width: "100%",
  //           height: "100%",
  //           fontSize: "40px",
  //           color: "white",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           cursor: lockColors ? "pointer" : "default",
  //         }}
  //       />
  //   ];

  // const mainProjectPath = `${item.clientId}/${item.projectId}`;
  // const [openAssetBrower, setOpenAssetBrower] = React.useState(false);
  // let image = null;

  const [bgAssetFullPath, setbgAssetFullPath] = useState("");
  useEffect(
    () => {
      const test = () => {
        setbgAssetFullPath("");
        console.log("update list item", listItem);
        if (listItem.assets !== undefined) {
          getTempImagePath(listItem.assets.name);
        }
      };
      test();

      return () => {
        console.log("cleanup funtion triggered :>> ");
      };
    },
    [listItem.assets]
  );
  const getTempImagePath = async _imgname => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails
      .id}/${_imgname}`;
    console.log("_path", _path);
    await AssetDatabase.getAsset(_path).then(res => {
      setbgAssetFullPath(res.link);
    });
  };

  let title = null;
  let imageUsed = false;
  // const { enableCache } = item.data;
  if (listItem.assets !== undefined) {
    imageUsed = true;
    // let areaHeight = TourPlayer.height - (barMode === 'C' ? (2 * (TourPlayer.height / 100 * topPercentage)) : (TourPlayer.height / 100 * topPercentage));
    // let itemHeight = areaHeight / advancedSettings.size;
    title = (
      <ComponentFullImage
        item={item}
        asset={listItem.assets}
        viewStyle={{
          width: "100%",
          height: "100%",
          objectFit: "fill",
          display: "block"
        }}
      />
    );
    // title =
    //   enableCache === true ? (<ComponentFullImage item={item} asset={listItem.assets} viewStyle={{
    //   width: "100%",
    //   height: '100%',
    //   objectFit: "fill",
    //   display: 'block',
    // }} />) : (<img src={bgAssetFullPath} style={{width:'100%',height:itemHeight, objectFit:'fill'}} alt="logo" />)
  }

  let itemLink = null;
  console.log("listItem", listItem);
  if (listItem.backToPrevious === true) {
    itemLink = itemContext.previousItem;
  } else {
    siblings.forEach(element => {
      if (element.id === listItem.link) {
        itemLink = element;
      }
    });
  }
  if (previewMode || true) {
    return (
      <div
        // onClick={() => {
        //   let loadItem = itemLink
        //   console.log("list item clicked", listItem, loadItem)
        //   if (listItem.exitTour === true) {
        //     loadItem = "exit"
        //   }
        //   if (listItem.externalLinkEnabled === true) {
        //     loadItem = "externalLinkWithoutButton"
        //   }
        //   if (loadItem !== null) {
        //     updateItem(loadItem, previewMode, null, listItem)
        //   }
        // }}
        key={listCount + "listItem"}
        style={{
          height: 100 / advancedSettings.size + "%",
          width: "100%",
          // cursor: "pointer",
          //   border: "1px solid red",
          position: "relative"
        }}
        onClick={() => {
          if (previewMode) {
            handleSaveScrollPosition();
          }
        }}
      >
        {visibleGrid}
        {title}
      </div>
    );
  } else {
    if (!imageUsed) {
      if (listItem.title !== "") {
        //title = listItem.title;
      } else {
        //title = `Item ${listCount+1}`;
      }
    }
    return (
      <Grid
        key={listCount + "listItem"}
        onClick={() => {
          setCurrentItem(listItem);
        }}
        style={{
          height: 100 / advancedSettings.size + "%",
          width: "100%",
          backgroundColor: "#fcbfcb33"
        }}
      >
        {title}
      </Grid>
    );
  }
}

import React, { useContext, useState, useEffect } from 'react';

import { ItemContext } from '../../ComponentLoader';
import { Grid } from "@material-ui/core"
import AssetDatabase from '../../../../utils/assetDatabase';
import ComponentFullImage from '../../ComponentFullImage';

// const styles = theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

// const useStyles = makeStyles(theme => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   button: {
//     margin: theme.spacing(1),
//     backgroundColor:'#B9D949'
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 300,
//   },
//   formControl: {
//     margin: theme.spacing(2),
//   },
// }));

export default function ListItem(props) {
  const itemContext = useContext(ItemContext);
  const { item, previewMode, siblings, updateItem, TourPlayer } = itemContext;
  const { advancedSettings } = item.data;
  const { listItem, listCount, setCurrentItem, topPercentage, barMode, handleSaveScrollPosition } = props;
  //const [title, setTitle] = React.useState(currentItem!==null?currentItem.title:'');
  // const classes = useStyles();

  console.log('item.data :>> ', item.data);

  // const mainProjectPath = `${item.clientId}/${item.projectId}`;
  // const [openAssetBrower, setOpenAssetBrower] = React.useState(false);
  // let image = null;

  const [bgAssetFullPath, setbgAssetFullPath] = useState('');
  useEffect(() => {
    const test = () => {
      setbgAssetFullPath('')
      console.log('update list item', listItem)
      if (listItem.assets !== undefined) {
        getTempImagePath(listItem.assets.name)
      }

    }
    test()

    return () => {
      console.log('cleanup funtion triggered :>> ');
    }

  }, [listItem.assets])
  const getTempImagePath = async (_imgname) => {
    let _path = `${item.clientId}/${item.projectId}/assets/${item.tourDetails.id}/${_imgname}`
    await AssetDatabase.getAsset(_path).then(res => {
      setbgAssetFullPath(res.link)
    })
  }


  let title = null;
  let imageUsed = false;
  // const { enableCache } = item.data;
  if (listItem.assets !== undefined) {
    imageUsed = true;
    // let areaHeight = TourPlayer.height - (barMode === 'C' ? (2 * (TourPlayer.height / 100 * topPercentage)) : (TourPlayer.height / 100 * topPercentage));
    // let itemHeight = areaHeight / advancedSettings.size;
    title = (<ComponentFullImage item={item} asset={listItem.assets} viewStyle={{
      width: "100%",
      height: '100%',
      objectFit: "fill",
      display: 'block',
    }} />)
    // title =  
    //   enableCache === true ? (<ComponentFullImage item={item} asset={listItem.assets} viewStyle={{
    //   width: "100%",
    //   height: '100%',
    //   objectFit: "fill",
    //   display: 'block',
    // }} />) : (<img src={bgAssetFullPath} style={{width:'100%',height:itemHeight, objectFit:'fill'}} alt="logo" />)
  }

  let itemLink = null;
  console.log('listItem', listItem)
  if (listItem.backToPrevious === true) {
    itemLink = itemContext.previousItem;
  }
  else {
    siblings.forEach(element => {
      if (element.id === listItem.link) {
        itemLink = element;
      }
    });
  }
  if (previewMode || true) {
    return (
      <div
        onClick={() => {
          if (previewMode) {
            handleSaveScrollPosition();
          }
          let loadItem = itemLink
          console.log('list item clicked', listItem)
          if (listItem.exitTour === true) {
            loadItem = 'exit'
          }
          if (listItem.externalLinkEnabled === true) {
            loadItem = "externalLinkWithoutButton"
          }
          if (loadItem !== null) {
            updateItem(loadItem, previewMode, listItem, listItem)
          }
        }}
        key={listCount + 'listItem'}
        style={{ height: (100 / advancedSettings.size) + '%', width: '100%', cursor: 'pointer' }}
      >
        {title}
      </div>
    );
  }
  else {
    if (!imageUsed) {
      if (listItem.title !== '') {
        //title = listItem.title;
      }
      else {
        //title = `Item ${listCount+1}`;
      }
    }
    return (
      <Grid key={listCount + 'listItem'} onClick={() => { setCurrentItem(listItem) }} style={{ height: (100 / advancedSettings.size) + '%', width: '100%', backgroundColor: '#fcbfcb33' }}>
        {
          title
        }
      </Grid>
    );
  }




}



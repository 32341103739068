import React, { forwardRef, useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, Grid, Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import MaterialTable from "material-table";
import moment from "moment";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import { formatBytes } from "../../utils/mathFunctions";
import { QRCode } from "react-qrcode-logo";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import Database from "../../utils/database";
import QRCodeFn from 'qrcode'
import JSZip from "jszip";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) =>
    <ChevronRight {...props} ref={ref} />
  ),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) =>
    <ChevronLeft {...props} ref={ref} />
  ),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Export: forwardRef((props, ref) =>
    <SystemUpdateAltIcon {...props} ref={ref} />
  ),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const VideoAssetViewer = props => {

  const { open, handleClose } = props;
  const qrcodeRef = useRef([])

  const { clientIdSlug } = useParams()

  const [loading, setLoading] = useState(false);
  const [videoList, setvideoList] = useState([])
  const [selectRow, setSelectRow] = useState([])

  useEffect(() => {
    setLoading(true)
    Database.getVideoList(clientIdSlug).then(res => {
      setvideoList(res)
      setLoading(false)
    }).catch(err => setLoading(false))
  }, [])


  // const [fileData, setFileData] = useState([]);
  // const [defaultSearchValue, setDefaultSearchValue] = useState("");
  // const [searchValue, setSearchValue] = useState("");
  // const [openProgress, setOpenProgress] = useState(false);
  // const [updateMetaData, setUpdateMetaData] = useState(false);
  // const [filesCount, setFilesCount] = useState("calculating... ");
  // const [fileSelected, setFileSelected] = useState(null);
  // const [selectedRow, setSelectedRow] = useState(null);
  // const [disableActionBtn, setDisableActionBtn] = useState(true);
  // const [assetRequirements, setAssetRequirements] = useState(
  //   props.assetRequirements
  // );

  return (
    <Dialog
      fullScreen
      fullWidth={true}
      maxWidth="xl"
      // open={open}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      style={{ marginTop: 80 }}
    >
      {/* <DialogTitle id="max-width-dialog-title" style={{backgroundColor:'#767676'}}> */}
      <DialogContent style={{ backgroundColor: "#fff" }}>
        <Grid container direction="column">
          <Grid item>
            <Grid
              container
              direction={"row"}
              justify={"space-between"}
              style={{}}
            >
              <Grid item style={{ padding: 10 }}>
                <h2>
                  Video Assets ( {loading ? "Loading..." : videoList.length}  )
                </h2>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <MaterialTable
              key={"videoManager"}
              style={{ width: "100%" }}
              title=""
              columns={[
                {
                  title: "Preview", field: "id", editable: "never", render: (rowItem, index) => {
                    // console.log('rowItem, index', rowItem, index)
                    // return rowItem.tableData.id + 1
                    return (
                      <ReactPlayer
                        key={"video"}
                        url={rowItem.link}
                        preload={true}
                        controls={false}
                        playing={false}
                        muted={true}
                        width={"200px"}
                        height={"200px"}
                      />
                    )
                  }
                },
                { title: "Title", field: "name", editable: "never" },
                { title: "Type", field: "type", editable: "never" },
                {
                  title: "Size",
                  field: "size",
                  editable: "never",
                  render: rowItem => formatBytes(rowItem.size)
                },
                {
                  title: "QR Code", field: "qrCode", editable: "never", render: (rowItem) => {
                    console.log('rowItem', rowItem)
                    const path = rowItem.path.split("/").filter(val => val !== "videos").join("/")
                    return (
                      <QRCode
                        id="videoPage"
                        size={180}
                        renderAs={'svg'}
                        value={`${window?.location?.origin}/video/${path}`}
                      // ref={(ref) => qrcodeRef.current[rowItem.id] = ref}
                      />
                    )
                  }
                }
              ]}
              icons={tableIcons}
              data={videoList}
              options={{
                selection: true,
                grouping: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 15, 20, 25, 30, 40, 50, 100]
              }}
              onSelectionChange={(rowItem) => setSelectRow(rowItem)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#767676' }}>
        <Grid container >
          <Button disabled={selectRow.length === 0} onClick={async () => {
            console.log('selectRow', selectRow)

            if(selectRow.length > 1){

              const zip = new JSZip();

              for (let index = 0; index < selectRow.length; index++) {
                const rowItem = selectRow[index];
                const path = rowItem.path.split("/").filter(val => val !== "videos").join("/")
                const qrDataURL = await QRCodeFn.toDataURL(`${window?.location?.origin}/video/${path}`, { width: 500, height: 500 })
                const base64Data = qrDataURL.split(',')[1]; 
                console.log('data', qrDataURL)
                zip.file(`${rowItem.name}-qrcode.png`, base64Data, { base64 : true})
              }

              const zipBlob = await zip.generateAsync({ type: 'blob' });
              const a = document.createElement('a');
              a.href = URL.createObjectURL(zipBlob);
              a.download = `qrcode.zip`; // Set the file name
              a.click();
             
            }else{

              const rowItem = selectRow[0];
              const path = rowItem.path.split("/").filter(val => val !== "videos").join("/")
              const data = await QRCodeFn.toDataURL(`${window?.location?.origin}/video/${path}`, { width: 500, height: 500 })
              const a = document.createElement('a');
              a.href = data;
              a.download = `${rowItem.name}-qrcode.png`; // Set the file name
              a.click();
              

            }
            
          
          }}
            variant='contained' color="secondary">
            Download QR Code
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default VideoAssetViewer;

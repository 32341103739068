import React, { useState, useContext } from "react";
import { ItemContext } from "../../ComponentLoader";
import DefaultSettings from "../../../../defaultSettings/settings";

const SegementListBlock = props => {
  const {
    index,
    charIndex,
    char,
    listItem,
    single,
    mouseDown,
    lockColor,
    selectedBlocks,
    onSelectBlock,
    getSelectedItem,
    onDeleteBlock,
    altDown
  } = props;

  const itemContext = useContext(ItemContext);

  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingComponentSelector,
    editMode
  } = itemContext;
  const { advancedSettings } = item.data;

  const [color, setColor] = useState("");
  // console.log(
  //   "selectBlock",
  //   `${listItem.index}-${char}`,
  //   color,
  //   lockColor,
  //   item,
  //   selectedBlocks
  // );

  const selectBlock = selectedBlocks.includes(
    single ? `${listItem.index}` : `${listItem.index}_${char}`
  );

  const onMouseOverBlock = () => {
    if (!lockColor) {
      if (mouseDown) {
        if (single) {
          onSelectBlock();
        } else {
          onSelectBlock(char);
        }
      } else {
        setColor("#00aaff88");
      }
    }
  };

  const onMouseOutBlock = () => {
    setColor("");
  };

  const onClickBlock = () => {
    if (!lockColor) {
      if (selectBlock) {
        if (single) {
          onDeleteBlock();
        } else {
          onDeleteBlock(char);
        }
      } else {
        if (single) {
          onSelectBlock();
        } else {
          onSelectBlock(char);
        }
      }
    } else {
      if (altDown) {
        // console.log("clicked on button" + JSON.stringify(buttonFound))
        let loadItem = null;
        const buttonFound = advancedSettings.listItems[index].single
          ? advancedSettings.listItems[index]
          : advancedSettings.listItems[index][char];

        console.log("buttonFound, index,char", buttonFound, index, char);
        // if (
        //   buttonFound.sendMessage === true &&
        //   item.data.advancedSettings.emailSettings.message &&
        //   item.data.advancedSettings.emailSettings.dest
        // ) {
        //   console.log("send Message has been clicked in Blocks.js")
        //   const { dest, subject, emailFromShort, heading, message, sending } =
        //     item.data.advancedSettings.emailSettings
        //   item.data.advancedSettings.emailSettings.sending = true
        //   updateItem(item)

        //   fetch(
        //     `https://us-central1-imagineear-cms.cloudfunctions.net/sendMail?dest=${dest}&emailFromShort=${emailFromShort}&subject=${subject}&heading=${heading}&message=${message}`
        //   )
        //     .then((res) => {
        //       console.log(
        //         "Email has been sent successfully! Message, res: ",
        //         message,
        //         res
        //       )
        //       item.data.advancedSettings.emailSettings.sending = false
        //       item.data.advancedSettings.emailSettings.sent = true
        //       updateItem(item)
        //     })
        //     .catch((error) =>
        //       console.log("error with email settings fetch: ", error)
        //     )
        // }

        if (buttonFound.backToPrevious === true) {
          console.log("next item:", itemContext.previousItem.data.title);
          loadItem = itemContext.previousItem;
        } else if (buttonFound.exitTour === true) {
          loadItem = "exit";
        } else if (buttonFound.toKeypad === true) {
          loadItem = DefaultSettings.keypadItem;
          loadItem.clientId = item.clientId;
          loadItem.projectId = item.projectId;
          loadItem.tourDetails = item.tourDetails;
          loadItem.projectDetails = item.projectDetails;
          console.log("item inside projectDetails :>> ", item);
        } else if (buttonFound.externalLinkEnabled === true) {
          loadItem = "externalLink";
        } else {
          siblings.forEach(element => {
            //console.log("item to go to:", element.id, buttonFound.link)

            if (element.id === buttonFound.link) {
              console.log("next item:", element.data.title);
              loadItem = element;
            }
          });
        }
        // console.log("item to go to:", JSON.stringify(loadItem), siblings.length)
        // console.log("item to go to:", loadItem)
        if (!buttonFound.sendMessage) {
          updateItem(loadItem, previewMode, buttonFound, "Blocks onblockclick");
        }
      }
    }
  };

  const onDoubleClickBlock = () => {
    let lockBlocks = "";
    let listOfBlocks = [];
    let isEdit = false;

    if (lockColor) {
      const findBlock = single
        ? `${listItem.index}`
        : `${listItem.index}_${char}`;
      const lockBlock = advancedSettings.selected.find(block =>
        block.includes(findBlock)
      );

      isEdit = true;
      listOfBlocks = lockBlock.split(",");
      lockBlocks = lockBlock;

      // advancedSettings.selected.forEach(item => {
      //   const blocks = item.split(",");
      //   blocks.forEach(val => {

      //     if (val === `${listItem.index}_${char}`) {
      //       isEdit = true;
      //       listOfBlocks = blocks;
      //       lockBlocks = item;
      //     }
      //   });
      // });
    } else if (selectBlock) {
      listOfBlocks = selectedBlocks;
    } else {
      listOfBlocks = single
        ? [`${listItem.index}`]
        : [`${listItem.index}_${char}`];
    }

    openingComponentSelector(
      {
        button: single ? listItem : listItem[char],
        itemSelected: getSelectedItem(single ? "" : char),
        editMode
      },
      details => {
        const { button, action, selectedID } = details;
        if (listOfBlocks.length > 0) {
          listOfBlocks.forEach(val => {
            const [index, char] = val.split("_");
            // console.log("index, char", index, char);
            const listItem = advancedSettings.listItems[index].single
              ? advancedSettings.listItems[index]
              : advancedSettings.listItems[index][char];
            if (action === "update") {
              listItem.link = selectedID;
              listItem.backToPrevious = button.backToPrevious;
              listItem.exitTour = button.exitTour;
              listItem.toKeypad = button.toKeypad;
              listItem.returnLink = button.returnLink;
            } else if (action === "remove") {
              listItem.link = null;
              listItem.backToPrevious = null;
              listItem.exitTour = null;
              listItem.toKeypad = null;
              listItem.returnLink = null;
            }
          });

          if (action === "update" && !isEdit) {
            advancedSettings.selected.push(listOfBlocks.join(","));
            onDeleteBlock("ALL");
          }
          if (action === "remove") {
            if (isEdit) {
              advancedSettings.selected = advancedSettings.selected.filter(
                val => val !== lockBlocks
              );
            }
            setColor("");
            onDeleteBlock("ALL");
          }
        }
        if (item !== null) {
          updateItem(item, previewMode, "Blocks handle close");
        }
      },
      previewMode
    );
  };

  const onClickLoadBlock = () => {
    let loadItem = null;
    const clickListItem = single ? listItem : listItem[char];
    if (clickListItem.backToPrevious === true) {
      loadItem = itemContext.previousItem;
    } else if (clickListItem.toKeypad === true) {
      loadItem = DefaultSettings.keypadItem;
      loadItem.clientId = item.clientId;
      loadItem.projectId = item.projectId;
      loadItem.tourDetails = item.tourDetails;
      loadItem.projectDetails = item.projectDetails;
    } else {
      siblings.forEach(element => {
        if (element.id === clickListItem.link) {
          loadItem = element;
        }
      });
    }

    console.log("list item clicked", clickListItem, loadItem);
    if (clickListItem.exitTour === true) {
      loadItem = "exit";
    }
    if (clickListItem.externalLinkEnabled === true) {
      loadItem = "externalLinkWithoutButton";
    }
    if (loadItem !== null) {
      updateItem(loadItem, previewMode, clickListItem, clickListItem);
    }
  };

  if (single) {
    if (!previewMode) {
      return (
        <div
          onClick={onClickBlock}
          onDoubleClick={onDoubleClickBlock}
          onMouseOver={onMouseOverBlock}
          onMouseOut={onMouseOutBlock}
          style={{
            position: "absolute",
            // backgroundColor: lockColor ? lockColor : color,
            backgroundColor: lockColor
              ? lockColor
              : selectBlock ? "#ffaa00dd" : color,
            width: "100%",
            height: "100%",
            border: "1px solid #00ffaaAA",
            fontSize: "40px",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer"
          }}
        />
      );
    } else {
      return (
        <div
          onClick={onClickLoadBlock}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            fontSize: "40px",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: lockColor ? "pointer" : "default"
          }}
        />
      );
    }
  } else {
    if (!previewMode) {
      return (
        <div
          onClick={onClickBlock}
          onDoubleClick={onDoubleClickBlock}
          onMouseOver={onMouseOverBlock}
          onMouseOut={onMouseOutBlock}
          style={{
            position: "absolute",
            left: `${charIndex * 25}%`,
            backgroundColor: lockColor
              ? lockColor
              : selectBlock ? "#ffaa00dd" : color,
            width: "25%",
            height: "100%",
            border: "1px solid #00ffaaAA",
            fontSize: "40px",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer"
          }}
        >
          <p>
            {char}
          </p>
        </div>
      );
    } else {
      return (
        <div
          onClick={onClickLoadBlock}
          style={{
            cursor: lockColor ? "pointer" : "default",
            position: "absolute",
            left: `${charIndex * 25}%`,
            width: "25%",
            height: "100%",
            fontSize: "40px",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        />
      );
    }
  }
};

export default SegementListBlock;

// export const SegementListBlock = props => {
//   const {
//     index,
//     char,
//     listItem,
//     lockColor,
//     getSelectedItem,
//     selectedBlocks,
//     onDeleteBlock,
//     onSelectBlock
//   } = props;

//   const itemContext = useContext(ItemContext);

//   const {
//     item,
//     previewMode,
//     siblings,
//     updateItem,
//     openingComponentSelector,
//     editMode
//   } = itemContext;
//   const { advancedSettings } = item.data;

//   const [color, setColor] = useState("");

//   const selectBlock = selectedBlocks.includes(`${listItem.index}`);

//   const doubleClick = () => {
//     let lockBlocks = "";
//     let listOfBlocks = [];
//     let isEdit = false;

//     if (lockColor) {
//       const findBlock = single
//         ? `${listItem.index}`
//         : `${listItem.index}_${char}`;
//       const lockBlock = advancedSettings.selected.find(block =>
//         block.includes(findBlock)
//       );

//       isEdit = true;
//       listOfBlocks = lockBlock.split(",");
//       lockBlocks = lockBlock;
//     } else if (selectBlock) {
//       listOfBlocks = selectedBlocks;
//     } else {
//       listOfBlocks = single
//         ? [`${listItem.index}`]
//         : [`${listItem.index}_${char}`];
//     }

//     openingComponentSelector(
//       {
//         button: single ? listItem : listItem[char],
//         itemSelected: getSelectedItem(single ? "" : char),
//         editMode
//       },
//       details => {
//         const { button, action, selectedID } = details;
//         console.log("details", details);
//         if (listOfBlocks.length > 0) {
//           listOfBlocks.forEach(val => {
//             const [index, char] = val.split("_");
//             // console.log("index, char", index, char);
//             const listItem = advancedSettings.listItems[index].single
//               ? advancedSettings.listItems[index]
//               : advancedSettings.listItems[index][char];
//             if (action === "update") {
//               listItem.link = selectedID;
//               listItem.backToPrevious = button.backToPrevious;
//               listItem.exitTour = button.exitTour;
//               listItem.toKeypad = button.toKeypad;
//               listItem.returnLink = button.returnLink;
//             } else if (action === "remove") {
//               listItem.link = null;
//               listItem.backToPrevious = null;
//               listItem.exitTour = null;
//               listItem.toKeypad = null;
//               listItem.returnLink = null;
//             }
//           });

//           if (action === "update" && !isEdit) {
//             advancedSettings.selected.push(listOfBlocks.join(","));
//             onDeleteBlock("ALL");
//           }
//           if (action === "remove") {
//             if (isEdit) {
//               advancedSettings.selected = advancedSettings.selected.filter(
//                 val => val !== lockBlocks
//               );
//             }
//             setColor("");
//             onDeleteBlock("ALL");
//           }
//         }
//         if (item !== null) {
//           updateItem(item, previewMode, "Blocks handle close");
//         }
//       },
//       previewMode
//     );
//   };
// };

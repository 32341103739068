import React, { Fragment, useEffect, useState } from "react"
import { withStyles, createTheme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import DropDownMenu from "../dropDownMenu"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import ComponentDropDownMenu from "./componentDropDownMenu"
import TourSectionManager from "../tourManagerComponents/TourSectionManager"
import Database from "../../utils/database"
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MuiThemeProvider,
} from "@material-ui/core"
import MaterialTable from "material-table"
import tableIcons from "../componentTable/TableIcons"
const theme = createTheme({})

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function NewComponent(props) {
  const { settings } = props
  const [title, setTitle] = useState("")
  const [keypadCode, setKeypadCode] = useState("")
  const [type, setType] = useState("Menu")
  const [sectionSelection, setSectionSelection] = useState("")
  const [editMode, setEditMode] = useState(false)
  const [openTourSectionManager, setOpenTourSectionManager] = useState(false)
  const [sectionList, setSectionList] = useState([])
  let tempIndex = undefined
  const [referenceCode, setReferenceCode] = useState(undefined)
  //let referenceCode = undefined;
  const { tourDetails, allSections, clientDetails, projectDetails } =
    props.tourState

  const makeCode = (length) => {
    let result = ""
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
  const getCode = () => {
    let references
    if (clientDetails.data.references === undefined) {
      references = []
    } else {
      references = clientDetails.data.references
    }
    let code = makeCode(4)
    while (references.indexOf(code) >= 0) {
      code = makeCode(4)
    }
    return code
  }
  if (tourDetails !== undefined) {
    tempIndex =
      tourDetails.data.index === undefined ? 0 : tourDetails.data.index + 1
    console.log("clientDetails: ", clientDetails, projectDetails)
    //referenceCode = props.clientCode+tourDetails.data.tourCode+(tourDetails.data.index===undefined?'0000':('000' + (tourDetails.data.index+1)).slice(-4))
  }

  const classes = useStyles()

  const [seletedComponents, setSeletedComponents] = useState(null)
  const handleClose = (action) => {
    if (action === "save" || action === "save_edit") {
      if (clientDetails.data.references === undefined) {
        clientDetails.data.references = []
      }
      clientDetails.data.references.push(referenceCode)

      Database.updateClient(clientDetails)
    }
    setTitle("")
    setEditMode(false)
    tourDetails.data["index"] = tempIndex

    // Note: Login To create a new Component and the Props are sent in onClose Event

    props.onClose(
      {
        title,
        keypadCode,
        sectionSelection,
        type,
        referenceCode,
        preSelectedComponent:
          seletedComponents !== null ? seletedComponents : null,
        tourDetails: tourDetails,
      },
      action
    )
    // Note: Login To create a new Component and the Props are sent in onClose Event Ends here
  }
  const changeTitle = (title) => {
    console.log(title)
    setTitle(title)
  }
  const changeType = (type) => {
    console.log(type)
    setType(type)
  }
  useEffect(() => {
    //  const abortController = new AbortController();
    //setSelectedValue('sdfsfgdfsg')
    if (projectDetails.data._tempFeatures.indexOf("Menu") >= 0) {
      setType("Menu")
    } else {
      setType(projectDetails.data._tempFeatures[0])
    }

    setReferenceCode(props.clientCode + "-" + getCode())
  }, [props.open])
  //console.log('GetComponentList',GetComponentList());
  useEffect(() => {
    //  const abortController = new AbortController();
    //setSelectedValue('sdfsfgdfsg')
    console.log("list should update on new component")
    let sections = [{ title: "*Manage Sections*", value: "Add new section" }]
    allSections.forEach((element) => {
      sections.push({ value: element.id, title: element.data.section })
    })
    setSectionList(sections)
  }, [allSections])

  const [tourList, setTourList] = useState([])
  const [selectedComponentsOnly, setSelectedComponentsOnly] = useState(false)
  const [tourComponents, setTourComponents] = useState([])
  const [componentList, setcomponentList] = useState([])

  // TODO: check why this is breaking the Back button functionalities
  useEffect(() => {
    const getComponentList = async () => {
      let tourSettings = {
        clientId: settings.clientId,
        projectId: settings.projectId,
        tourId: settings.tourId,
      }
      let componentList = await Database.getComponentsList(tourSettings)
      setcomponentList(componentList)
    }
    getComponentList()
  }, [])

  console.log("componentList :>> ", componentList)
  function restrictUserInputwithNumber(e, val) {
    if (e.target.value.length >= val) {
      console.log("e.keyCode object :>> ", e.key)
      if (
        [
          "Backspace",
          "ArrowLeft",
          "Delete",
          "ArrowLeft",
          "ArrowUp",
          "ArrowRight",
          "ArrowDown",
        ].includes(e.key)
      ) {
        return true
      } else {
        console.log("e.keyCode object :>> :>> ", e.key)
        return e.preventDefault()
      }
    }
  }
  return (
    <div>
      <Dialog
        onClose={() => handleClose("close")}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose("close")}
        >
          <h3>New component/feature</h3>
        </DialogTitle>
        <DialogContent dividers>
          <span>
            Select the component type and title to create a new component.
            Section can also be added at this point
          </span>
          <Grid>
            {!selectedComponentsOnly && (
              <Fragment>
                <ComponentDropDownMenu
                  items={projectDetails.data._tempFeatures}
                  value={type}
                  label={"Type"}
                  changeTitle={changeType}
                  style={{ width: "100%" }}
                />
                <span
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  or
                </span>
              </Fragment>
            )}
            {componentList.length && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedComponentsOnly}
                    onChange={() => {
                      console.log("tour components:", componentList)
                      let componentRowData = []
                      for (const component of componentList) {
                        componentRowData.push({
                          section: component.data.section,
                          title: component.data.title,
                          type: component.data.type,
                          code: component.data.type,
                          id: component.id,
                          data: component,
                        })
                      }
                      setSelectedComponentsOnly(
                        (selectedComponentsOnly) => !selectedComponentsOnly
                      )
                      setTourComponents(componentRowData)
                    }}
                  />
                }
                label="Clone From Existing Component"
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "block",
                }}
              />
            )}
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="Title"
              value={title}
              onChange={(event) => {
                //itemFound.title = event.target.value;
                setTitle(event.target.value)
              }}
              margin="normal"
              style={{ margin: "0px", marginTop: "0px", width: "100%" }}
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label="Keypad Code"
              value={keypadCode}
              type="number"
              pattern="/^-?\d+\.?\d*$/"
              onKeyDown={(e) => restrictUserInputwithNumber(e, 4)}
              onChange={(event) => {
                //itemFound.title = event.target.value;
                setKeypadCode(event.target.value)
              }}
              margin="normal"
              style={{ margin: "0px", marginTop: "15px", width: "100%" }}
              variant="outlined"
            />
            <DropDownMenu
              key={"section_selected"}
              items={sectionList}
              autoUpdate={true}
              value={sectionSelection}
              changeTitle={(value) => {
                if (value === "Add new section") {
                  //itemFound.section = value
                  setOpenTourSectionManager(true)
                } else {
                  console.log("changing title to:", value)
                  setSectionSelection(value)
                  //itemFound.section = value
                  //updateItem(item, previewMode, "ComponentSettings DropDownMenu")
                }
              }}
              style={{ width: "100%" }}
              label={"Section"}
            />
            {selectedComponentsOnly ? (
              <Fragment>
                <hr />
                <p>
                  <em>
                    <strong>Note:- </strong>Select Component from below list to
                    replicate a New Component
                  </em>
                </p>
                <MuiThemeProvider theme={theme}>
                  <MaterialTable
                    style={{ width: "100%" }}
                    title="Components"
                    columns={[
                      { title: "Title", field: "title", defaultSort: "asc" },
                      { title: "Type", field: "type", defaultSort: "asc" },
                    ]}
                    icons={tableIcons}
                    data={tourComponents}
                    options={{
                      pageSize: tourComponents.length,
                      // selection: true,
                      pageSizeOptions: [20, 40, 60, 100],
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          seletedComponents !== null
                            ? rowData.id === seletedComponents.id
                              ? "#cccccc"
                              : "#fff"
                            : "#ffffff",
                      }),
                    }}
                    onRowClick={(event, rows) => {
                      tourComponents.forEach((element) => {
                        if (element.tableData.checked)
                          if (rows.indexOf(element) >= 0) {
                            console.log(
                              "element inside tourComponent :>> ",
                              element
                            )
                            console.log(
                              "rows inside onSelectionChange :>> ",
                              rows
                            )
                            console.log(
                              "element.tableData.checked :>> ",
                              element.tableData.checked
                            )
                            element.tableData.checked =
                              !element.tableData.checked
                          }
                      })
                      // rows.tableData.checked = true;
                      setSeletedComponents(rows)
                      console.log(
                        "You selected " + JSON.stringify(rows) + " rows"
                      )
                    }}
                  />
                </MuiThemeProvider>
              </Fragment>
            ) : null}

            <p style={{ marginTop: 5, color: "grey" }}>
              Reference: {referenceCode}
            </p>
          </Grid>
          <TourSectionManager
            enableSelection={true}
            sections={allSections}
            section={sectionSelection}
            settings={{
              id: null,
              projectId: settings.projectId,
              clientId: settings.clientId,
              tourId: settings.tourId,
              data: null,
            }}
            open={openTourSectionManager}
            onClose={(sections) => {
              setSectionSelection(sections)
              setOpenTourSectionManager(false)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container justify={"space-between"}>
            <Grid item>
              <Button
                variant="contained"
                autoFocus
                onClick={() => handleClose("close")}
                color="secondary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                autoFocus
                onClick={() => handleClose("save_edit")}
                color="primary"
                style={{ marginRight: 20 }}
              >
                Create and Edit
              </Button>
              <Button
                variant="contained"
                autoFocus
                onClick={() => handleClose("save")}
                color="primary"
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import React, { Component, useContext } from "react"
import ReactFileReader from "react-file-reader"
import Block from "./Block"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import GridList from "@material-ui/core/GridList"
import PropTypes from "prop-types"

import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Dialog from "@material-ui/core/Dialog"

import { blue } from "@material-ui/core/colors"
import BlocksLinkTo from "./BlocksLinkTo"

import { ItemContext } from "../../../ComponentLoader"
import KeyboardEventHandler from "react-keyboard-event-handler"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"
import ComponentSelector from "./ComponentSelector"
import DefaultSettings from "../../../../../defaultSettings/settings"
//import ReactImageMagnify from 'react-image-magnify';

//import '../../styles/examples.css';
/*const styles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      
      
    },
  });*/

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

export function getRandomColor() {
  var letters = "0123456789ABCDEF"
  var color = "#"
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color + "AA"
}

const Blocks = (props) => {
  //class Blocks extends Component {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingComponentSelector,
  } = itemContext
  const [state, setState] = React.useState({
    allblocks: [],
    selectedBlocks: [],
    buttonFound: null,
    previousBlock: null,
    selectingBlocks: false,
    shiftDown: false,
    altDown: false,
    openLinkTo: false,
    editMode: false,
    firstLoad: true,
    showClickable: false,
    topRows: [],
    middleRows: [],
    bottomRows: [],
  })
  const keyDown = (event) => {
    console.log(event.keyCode, state.shiftDown)
    // press shift
    if (event.keyCode === 16 && state.shiftDown === false) {
      console.log("shift to true")
      setState((prevState) => {
        return { ...prevState, shiftDown: true }
      })
      //Do whatever when esc is pressed
    }
  }

  const keyUp = (event) => {
    console.log("keyUp: " + event.keyCode)
    // press shift
    if (event.keyCode === 16 && state.shiftDown) {
      setState({ ...state, shiftDown: false })
      //Do whatever when esc is pressed
    }
  }
  //document.addEventListener("keydown", keyDown, false)
  //document.addEventListener("keyup", keyUp.bind(this), false)
  //console.log('state.showClickable:',state.showClickable)
  const GenerateBlocks = (blockRows, topBottom) => {
    const { classes } = props
    let generatedBlockRow = []
    blockRows.rows.forEach((blockRow) => {
      const numberCols = blockRows?.columns ? blockRows.columns : 8;
      let rows = []
      for (var i = 0; i < numberCols; i++) {
        const blockNumber = (blockRow - 1) * numberCols + i
        //console.log('block number:',blockNumber, blockRow);
        let tempBlock = (
          <Block
            key={blockNumber + "Block"}
            altDown={state.altDown}
            showClickable={state.showClickable}
            previewMode={previewMode}
            topBottom={topBottom}
            count={blockNumber}
            onTempClick={onBlockClick}
            onActiveClick={onActiveClick}
            onHighlightClickable={() => {
              setState({ ...state, showClickable: true })
            }}
            cancelHighlightClickable={() => {
              setState((prevState) => {
                return { ...prevState, showClickable: false }
              })
            }}
            onTempOver={onBlockOver}
            onTempUp={onBlockUp}
            linkIn={storeValue}
          />
        )
        if (blockRows.used === false) {
          rows.push(
            <Grid
              key={blockNumber}
              style={{ width: 100 / 8 + "%", height: "100%" }}
            ></Grid>
          )
        } else {
          rows.push(
            <Grid
              key={blockNumber}
              style={{ width: 100 / 8 + "%", height: "100%" }}
            >
              {tempBlock}
            </Grid>
          )
        }
      }
      generatedBlockRow.push(
        <Grid
          container
          style={{ width: "100%", height: blockRows.percentageHeight }}
        >
          {rows}
        </Grid>
      )
    })

    return <React.Fragment>{generatedBlockRow}</React.Fragment>
  }
  const onBlockClick = (id) => {
    setState({ ...state, selectingBlocks: true, previousBlock: id })
    calculateBlocks(id)
  }

  let history = useHistory()

  const onActiveClick = (id, editMode) => {
    let selectedBlocks = []
    if (editMode || state.altDown) {
      let buttonFound = null
      if (item.data.snapshots !== undefined) {
        item.data.snapshots.forEach((snapshot) => {
          const buttons = item.snapshots.find((obj) => obj.id === snapshot)
          console.log(
            "finding snapshot:",
            buttons,
            item.data.snapshots,
            item.snapshots
          )
          if (buttons !== undefined) {
            for (let index = 0; index < buttons.data.buttons.length; index++) {
              const button = buttons.data.buttons[index]
              let foundIndex = button.selectedBlocks.indexOf(id.state.count)
              if (foundIndex >= 0) {
                buttonFound = button
                selectedBlocks = button.selectedBlocks
              }
            }
          }
        })
      }
      for (let index = 0; index < item.data.buttons.length; index++) {
        const element = item.data.buttons[index]
        let foundIndex = element.selectedBlocks.indexOf(id.state.count)
        if (foundIndex >= 0) {
          buttonFound = element
          selectedBlocks = element.selectedBlocks
        }
      }
      //console.log(selectedBlocks, item.data.buttons[0], id.state.count);
      if (previewMode || state.altDown) {
        console.log('clicked on button' + JSON.stringify(buttonFound));
        let loadItem = null
        if (
          buttonFound.sendMessage === true &&
          item.data.advancedSettings.emailSettings.message &&
          item.data.advancedSettings.emailSettings.dest
        ) {
          console.log("send Message has been clicked in Blocks.js")
          const {
            dest,
            subject,
            emailFromShort,
            heading,
            message,
            sending,
          } = item.data.advancedSettings.emailSettings
          item.data.advancedSettings.emailSettings.sending = true
          updateItem(item)

          fetch(
            `https://us-central1-imagineear-cms.cloudfunctions.net/sendMail?dest=${dest}&emailFromShort=${emailFromShort}&subject=${subject}&heading=${heading}&message=${message}`
          )
            .then((res) => {
              console.log(
                "Email has been sent successfully! Message, res: ",
                message,
                res
              )
              item.data.advancedSettings.emailSettings.sending = false
              item.data.advancedSettings.emailSettings.sent = true
              updateItem(item)
            })
            .catch((error) =>
              console.log("error with email settings fetch: ", error)
            )
        }

        if (buttonFound.backToPrevious === true) {
          console.log("next item:", itemContext.previousItem.data.title)
          loadItem = itemContext.previousItem
        } else if (buttonFound.exitTour === true) {
          loadItem = "exit"
        } else if (buttonFound.toKeypad === true) {
          loadItem = DefaultSettings.keypadItem
          loadItem.clientId = item.clientId
          loadItem.projectId = item.projectId
          loadItem.tourDetails = item.tourDetails
          loadItem.projectDetails = item.projectDetails;
          console.log('item inside projectDetails :>> ', item);
        } else if (buttonFound.externalLinkEnabled === true) {
          loadItem = "externalLink"
        } else {
          siblings.forEach((element) => {
            //console.log("item to go to:", element.id, buttonFound.link)

            if (element.id === buttonFound.link) {
              console.log("next item:", element.data.title)
              loadItem = element
            }
          })
        }
        console.log("item to go to:", JSON.stringify(loadItem), siblings.length)
        console.log("item to go to:", loadItem)
        if (!buttonFound.sendMessage) {
          updateItem(loadItem, previewMode, buttonFound, "Blocks onblockclick",)
        }
      } else {
        let item = null
        siblings.forEach((element) => {
          if (element.id === buttonFound.link) {
            item = element
          }
        })
        //console.log('update state for active click', editMode);
        openingComponentSelector(
          { button: buttonFound, itemSelected: item, editMode },
          handleClose,
          previewMode
        )
        //setState({...state, openLinkTo:true,selectedBlocks, buttonFound, editMode});
      }
    } else if (!previewMode && !state.altDown) {
      for (var i = 0; i < state.allblocks.length; i++) {
        let block = state.allblocks[i]
        //console.log((block.state.count+1)+block.state.clicked);
        if (block.state.clicked && !block.state.locked) {
          selectedBlocks.push(block.state.count)
        }
      }
      console.log(selectedBlocks)
      //openingComponentSelector({object:null,itemSelected:null, editMode},()=>{},previewMode)
      openingComponentSelector(
        {
          button: { title: "", selectedBlocks, link: undefined },
          itemSelected: null,
          editMode,
        },
        handleClose,
        previewMode
      )
      //setState({...state, openLinkTo:true, selectedBlocks, editMode});
    }
  }
  const calculateBlocks = (id) => {
    if (state.previousBlock != null) {
      let previousRow = Math.floor(state.previousBlock.state.count / 8)
      let previousCol = state.previousBlock.state.count % 8
      let newRow = Math.floor(id.state.count / 8)
      let newCol = id.state.count % 8
      let gridTop = newRow > previousRow ? previousRow : newRow
      let gridBottom = newRow > previousRow ? newRow : previousRow
      let gridLeft = newCol > previousCol ? previousCol : newCol
      let gridRight = newCol > previousCol ? newCol : previousCol
      //alert(gridLeft+" "+gridRight+" "+gridTop+" "+gridBottom);
      for (var i = 0; i < state.allblocks.length; i++) {
        const tempRow = Math.floor(state.allblocks[i].state.count / 8)
        let tempCol = state.allblocks[i].state.count % 8

        if (
          tempCol <= gridRight &&
          tempCol >= gridLeft &&
          tempRow <= gridBottom &&
          tempRow >= gridTop &&
          state.shiftDown
        ) {
          state.allblocks[i].selectBlock()
        } else if (!state.shiftDown) {
          id.selectBlock()
          //state.allblocks[i].deSelectBlock()
        }

        // }
      }
    } else {
      for (var i = 0; i < state.allblocks.length; i++) {
        if (state.allblocks[i] != id) {
          //state.allblocks[i].deSelectBlock()
        }
      }
    }
  }
  const onBlockOver = (id) => {
    if (state.selectingBlocks) {
      calculateBlocks(id)
    } else {
      id.overBlock()
    }
  }
  const onBlockUp = (id) => {
    //if(!state.shiftDown){
    setState({
      ...state,
      selectingBlocks: false,
      previousBlock: null,
      showClickable: false,
    })
    //}
  }

  const storeValue = (id) => {
    state.allblocks.push(id)
    //console.log('storeValue state.firstLoad && state.allblocks.length:',state.firstLoad,state.allblocks.length);
    if (state.firstLoad) {
      //console.log('storeValue item.data.buttons.length:',item.data.buttons.length);

      if (item.data.snapshots !== undefined) {
        item.data.snapshots.forEach((snapshot) => {
          const buttons = item.snapshots.find((obj) => obj.id === snapshot)
          console.log(
            "finding snapshot:",
            buttons,
            item.data.snapshots,
            item.snapshots
          )
          if (buttons !== undefined) {
            for (let index = 0; index < buttons.data.buttons.length; index++) {
              const button = buttons.data.buttons[index]
              let randColor = "#AAAAAACC"
              for (let i = 0; i < button.selectedBlocks.length; i++) {
                //console.log('storeValue button.selectedBlocks:',button.selectedBlocks[i]);
                let index = state.allblocks.findIndex(
                  (element) => element.state.count === button.selectedBlocks[i]
                )
                if (index >= 0) {
                  const element = state.allblocks[index]
                  console.log("block:", element.state.locked)
                  element.setState({ locked: true, randomColor: randColor })
                }
              }
            }
          }
        })
      }
      for (let index = 0; index < item.data.buttons.length; index++) {
        //console.log('storeValue button.selectedBlocks: checking buttons')
        const button = item.data.buttons[index]
        let randColor = getRandomColor()
        //console.log('storeValue button.selectedBlocks length:',button.selectedBlocks.length);
        for (let i = 0; i < button.selectedBlocks.length; i++) {
          let index = state.allblocks.findIndex(
            (element) => element.state.count === button.selectedBlocks[i]
          )

          if (index >= 0) {
            const element = state.allblocks[index]
            //console.log('storeValue button.selectedBlocks:',state.allblocks.length,button.selectedBlocks[i], index,element);
            element.setState({ locked: true, randomColor: randColor })
          }
        }
      }

      //console.log('item tour details');
      //setState({...state, firstLoad:false});
    }
  }
  const handleClose = (details) => {
    //button, action
    console.log("handleclose:", details)
    const { button, action, selectedID, backToPrevious, returnLink } = details
    console.log("updateComponent ==> ", item.id, item.data, button)

    console.log("on close button: ", button)
    setState({ ...state, openLinkTo: false, buttonFound: null })

    let buttonFound = null
    for (let index = 0; index < item.data.buttons.length; index++) {
      const element = item.data.buttons[index]
      let foundIndex = element.selectedBlocks.indexOf(button.selectedBlocks[0])
      if (foundIndex >= 0) {
        buttonFound = index
      }
    }

    if (action === "update") {
      console.log("Return: ", button)
      if (buttonFound === null) {
        item.data.buttons.push(button)
      } else {
        item.data.buttons[buttonFound] = button
      }

      let randColor = getRandomColor()
      for (let index = 0; index < button.selectedBlocks.length; index++) {
        console.log(
          "button.selectedBlocks[index]",
          button.selectedBlocks[index]
        )
        const element = state.allblocks.find(
          (obj) => obj.state.count === button.selectedBlocks[index]
        )
        element.setState({ locked: true, randomColor: randColor })
      }
    } else if (action === "remove") {
      console.log("delete button")
      if (buttonFound !== null) {
        item.data.buttons = item.data.buttons
          .slice(0, buttonFound)
          .concat(
            item.data.buttons.slice(buttonFound + 1, item.data.buttons.length)
          )
      }

      for (let index = 0; index < button.selectedBlocks.length; index++) {
        const element = state.allblocks.find(
          (obj) => obj.state.count === button.selectedBlocks[index]
        )
        //element.setState({locked:false});
        element.resetBlock()
      }
    }
    //setSelectedValue(value);
    console.log("item buttons:", item.data.buttons)
    updateItem(item, previewMode, "Blocks handle close")
  }

  let topRows = []
  let bottomRows = []
  let middleRows = []
  if (props.settings.topBlocks !== undefined) {
    topRows = GenerateBlocks(props.settings.topBlocks, true)
  }
  if (props.settings.middleBlocks) {
    middleRows = GenerateBlocks(props.settings.middleBlocks, false)
  }
  if (props.settings.bottomBlocks) {
    bottomRows = GenerateBlocks(props.settings.bottomBlocks, true)
  }

  const { classes } = props

  return (
    <Grid
      style={{ width: "100%", height: "100%" }}
      onMouseLeave={() => {
        //setState({ ...state, selectingBlocks: false })
      }}
    >
      {topRows}
      {middleRows}
      {bottomRows}
      <KeyboardEventHandler
        handleKeys={["shift"]}
        onKeyEvent={(key, e) => {
          console.log('only handle "a" key')
          if (!state.shiftDown) {
            console.log("shift to true")
            setState((prevState) => {
              return { ...prevState, shiftDown: true }
            })
            //Do whatever when esc is pressed
          }
        }}
      />
      <KeyboardEventHandler
        handleKeys={["shift"]}
        handleEventType={"keyup"}
        onKeyEvent={(key, e) => {
          console.log('only handle "a" key')
          if (state.shiftDown) {
            console.log("shift to true")
            setState((prevState) => {
              return { ...prevState, shiftDown: false }
            })
            //Do whatever when esc is pressed
          }
        }}
      />
      <KeyboardEventHandler
        handleKeys={["alt"]}
        onKeyEvent={(key, e) => {
          //console.log('only handle "a" key')
          if (!state.altDown) {
            console.log("altDown to true")
            setState((prevState) => {
              return { ...prevState, altDown: true }
            })
            //Do whatever when esc is pressed
          }
        }}
      />
      <KeyboardEventHandler
        handleKeys={["alt"]}
        handleEventType={"keyup"}
        onKeyEvent={(key, e) => {
          //console.log('only handle "a" key')
          if (state.altDown) {
            console.log("altDown to false")
            setState((prevState) => {
              return { ...prevState, altDown: false }
            })
            //Do whatever when esc is pressed
          }
        }}
      />
    </Grid>
  )
}
/* Blocks.propTypes = {
        classes: PropTypes.object.isRequired,
      };*/

export default Blocks

import React, { useEffect, useRef, useState } from "react"

import {
  Container,
  CssBaseline,
  Typography,
  Button,
  Grid,
} from "@material-ui/core"

import { isIOS } from "react-device-detect"

import Database from "../utils/database"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import { Header } from "../components/webAppComponents/Header"
import { LoadingIcon } from "../components/webAppComponents/LoadingIcon"
import APKData from "../utils/APKbuildData"

const APKWebapp = () => {
  const { previewID } = useParams()
  const [headerData, setHeaderData] = useState(null)

  const APKLinkFound = APKData.hasOwnProperty(previewID)

  useEffect(() => {
    ;(async () => {
      if (APKLinkFound) {
        const data = await Database.getBuildHeaderData(previewID)
        setHeaderData(data)
      }
    })()
  }, [])

  // console.log("headerData", headerData)

  if (!APKLinkFound) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ marginTop: "50px" }}>
          <h1 style={{ textAlign: "center" }}>Something went wrong</h1>
          <h1 style={{ textAlign: "center" }}>APK Link not found</h1>
        </div>
      </div>
    )
  }

  if (!headerData) {
    return (
      <Grid
        container
        justifyContent="center"
        style={{
          margin: "0 auto",
          width: "100vw",
        }}
      >
        <LoadingIcon />
      </Grid>
    )
  }

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters={true}
      style={{
        position: "relative",
        height: "100vh",
        backgroundColor: "#e9e9e9",
      }}
    >
      <Header
        primaryColour={headerData.color}
        headerUrl={headerData.icon}
        cacheEnable={true}
      />

      <Container
        maxWidth="xs"
        style={{
          overflow: "hidden",
          position: "relative",
          padding: 40,
        }}
      >
        <CssBaseline />

        <Typography
          component="h1"
          variant="h5"
          style={{ textAlign: "center", marginBottom: "40px" }}
        >
          Install App
        </Typography>

        {isIOS ? (
          <div>
            <p>
              For the best experience, click the "Share" button, scroll down and
              click "Add to Home Screen".
            </p>
            <p>
              This will create a shortcut to the tour on the home screen of your
              device.
            </p>
            <p>
              After opening and downloading each part of the tour, you will be
              able to access the content offline. Please do not clear the
              browser cache until you are done using the tour.
            </p>
          </div>
        ) : (
          <>
            <p>
              Click "Install" to add the tour to your device home screen. After
              opening and downloading each part of the tour, you will be able to
              access the content offline. Please do not clear the browser cache
              until you are done using the tour.
            </p>

            <Button
              // disabled={!installPrompt}
              color="secondary"
              variant="contained"
              onClick={async () => {
                window.open(APKData[previewID])
              }}
            >
              Install
            </Button>
          </>
        )}
      </Container>
    </Container>
  )
}

export default APKWebapp

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router"
import Database from "../utils/database"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import AddProject from "../components/projectComponents/addProject"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { UserContext } from "../App"
import UserPermissions from "../utils/userPermissions"
import Container from "@material-ui/core/Container"
import IconButton from "@material-ui/core/IconButton"
import ProjectItem from "../components/projectComponents/ProjectItem"

import CircularProgress from "@material-ui/core/CircularProgress"
import { ProjectContext } from "../contexts/ProjectContext"
import VideoAssetViewer from "../components/projectComponents/VideoAssetViewer";

const useStyles = makeStyles({
  card: {
    maxWidth: 275,
    margin: 10,
  },
  projectHeader: {
    whiteSpace: "nowrap",
    width: 250,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "black",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  addBtn: {
    position: "absolute",
    bottom: -18,
    padding: "12px 25px",
    borderRadius: 25,
    backgroundColor: "#97D700",
    fontSize: 12,
    fontWeight: "bold",
    transition: "all 0.2s",
    textTransform: "uppercase",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "all 0.2s",
      backgroundColor: "#97D700",
    },
  },
  videoBtn: {
    position: "absolute",
    right: 150,
    bottom: -18,
    padding: "12px 25px",
    borderRadius: 25,
    backgroundColor: "#97D700",
    fontSize: 12,
    fontWeight: "bold",
    transition: "all 0.2s",
    textTransform: "uppercase",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "all 0.2s",
      backgroundColor: "#97D700",
    },
  }
})

const Projects = () => {
  const userContext = useContext(UserContext)
  const classes = useStyles()
  let history = useHistory()
  let location = useLocation()
  let { clientIdSlug } = useParams()
  const { project, client, setClient, getClient } = useContext(ProjectContext)

  console.log("location:, clientIdSlug ", location, clientIdSlug)
  // let client = location.state.details
  // let client.id = client.id
  // let clientName = client.data.name
  let { path, url } = useRouteMatch()

  //console.log("Project props: ", props)
  //let clientLogo = location.state.clientLogo || location.state.details.data.logo
  const [projectList, setProjectList] = useState([
    //<LoadingIcon title={"Loading projects"} />,
  ])
  const [openAddProjectDialog, setOpenAddProjectDialog] = useState(false)
  const [openAssetViewer, setOpenAssetViewer] = useState(false)
  const [videoListSize, setVideoSize] = useState([])
  // const [clientDetails, setClientDetails] = useState(null)
  const manageProjectEnabled = UserPermissions.checkPermissions(
    UserPermissions.ManageProject
  )

  // useEffect(() => {
  //   if (client) {
  //     console.log("Getting Client from context! ", client)
  //   } else if (clientIdSlug && client?.id !== clientIdSlug) {
  //     console.log("Getting clientIdSlug! ")
  //     getClient(clientIdSlug)
  //   }
  // }, [client])

  //console.log('item:',item);
  useEffect(() => {
    if (client) {
      console.log("Getting Client from context! ", client)
    } else if (clientIdSlug && client?.id !== clientIdSlug) {
      console.log("Getting clientIdSlug! ")
      getClient(clientIdSlug)
    }

    const LoadProjects = async () => {
      await Database.getClientProjects(client.id, (data) => {
        console.log("getClientDetails updated:", data, client.id)
        let _temp = []

        let projectsArr = []

        if (
          userContext.userProfile?.userType === "client" &&
          userContext.userProfile?.projects.length > 0
        ) {
          console.log("Selective Projects View!")
          projectsArr = [...userContext.userProfile.projects]
        } else if (
          userContext.userProfile?.userType === "projectManager" ||
          userContext.userProfile?.userType === "admin" ||
          userContext.userProfile?.projects.length === 0
        ) {
          projectsArr = ["All"]
        }

        console.log("projectsArr: ", projectsArr)
        if (!projectsArr.includes("All")) {
          console.log("FILTERING!")
          //projects available to the user
          data = data.filter((project) =>
            projectsArr.includes(project.projectId)
          )
        }
        //console.log("projectsArr data: ", data)
        if (data) {
          var docs = data.map((obj) => {
            var _id = obj.projectId
            return {
              projectId: _id,
              tourList: obj.toursInfo,
              ...obj.data,
            }
          })
        }
        console.log("docs: ", docs)
        if (docs) {
          setProjectList(docs)
        }

      })
    }
    if (client) {
      console.log("CLIENT DETAILS EXIST!")
      LoadProjects()
    }
  }, [client])

  useEffect(() => {
    Database.getVideoListSize(clientIdSlug).then(res => setVideoSize(res))
  }, [])


  const handleClose = () => {
    setOpenAddProjectDialog(false)
  }

  if (!client) {
    console.log("NO CLIENT DETAILS!")
    return null
  }

  return (
    <Grid
      container
      style={{
        position: "absolute",
        top: 101,
        left: 0,
        right: 0,

        margin: "auto",
      }}
    >
      <Grid
        container
        justify="space-between"
        style={{
          backgroundColor: "#5c068c",
          paddingBottom: 10,
          position: "relative",
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            spacing={4}
            style={{ paddingBottom: 10 }}
          >
            <Grid item>
              <img
                src={client.data.logo}
                alt="client logo"
                style={{ maxHeight: 50, objectFit: "cover" }}
              />
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Typography
                  variant="h1"
                  component="h1"
                  style={{ color: "#fff" }}
                >
                  {`${client.data.name} Projects`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" style={{ position: "relative" }}>
            {videoListSize > 0 ? 
            <>
              <IconButton
                color="primary"
                aria-label="add a project"
                onClick={() => setOpenAssetViewer(true)}
                className={classes.videoBtn}
              >
                Video List
              </IconButton>
              <VideoAssetViewer open={openAssetViewer} handleClose={() => setOpenAssetViewer(false)} />
            </> : null}


            {/* <AssetsViewer
                  // handleClose={handleAssetsViewerClose}
                  open={openAssetViewer}
                  // mainProjectPath={mainProjectPath}
                  componentInfo={{
                    clientId: clientIdSlug,
                    // projectId: projectId,
                    // tourDetails: tourState.tourDetails,
                  }}
                  preSelectedAsset={undefined}
                  assetRequirements={undefined}
                  setBgImg={null}
                  isTopLevel={true}
                /> */}


            {!manageProjectEnabled ? null : (
              <IconButton
                color="primary"
                aria-label="add a project"
                onClick={() => setOpenAddProjectDialog(true)}
                className={classes.addBtn}
              >
                Add Project
              </IconButton>
            )}
          </Grid>
        </Container>
      </Grid>
      <Container maxWidth="lg">
        <Grid container style={{ padding: "20px 0px" }} justifyContent="center">
          {projectList.length ? (
            projectList.map((item, index) => (
              <ProjectItem
                project={item}
                client={client}
                key={item.projectId}
              />
            ))
          ) : (
            <CircularProgress />
          )}

          <AddProject
            open={openAddProjectDialog}
            clientInfo={{
              id: client.id,
              name: client.data.name,
            }}
            handleClose={handleClose}
          />
        </Grid>
      </Container>
    </Grid>
  )
}

export default withRouter(Projects)
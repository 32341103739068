import React, {
  Component,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ItemContext } from "../ComponentLoader"
import Blocks from "../tourPlayer/InteractiveLayer/blocks/Blocks"
import Button from "@material-ui/core/Button"
import AssetsViewer from "../assetManagement/AssetsViewer"
import AssetDatabase, { dbx } from "../../../utils/assetDatabase"
import DefaultSettings from "../../../defaultSettings/settings"
import { Box } from "@material-ui/core"
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual"
import Database from "../../../utils/database"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { Grid3x3TwoTone } from "@mui/icons-material"
import DatabaseIndexedDb from "../../../IndexedDB/DatabaseIndexedDb"
import AutoAssignButton from "../AutoAssignButton"
import KeyPadProjectSetting from "./KeyPadProjectSetting"
import { createBrowserHistory } from "history"
import { Fragment } from "react"
import { isIOS } from "react-device-detect"
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}))

export const KeypadSettings = {
  type: "keypad",
  title: "Keypad",
  support: ["iwa", "tourbuilder"],
  img: require("../../../images/menu_component.png"),
  color: "#525F11",
}
export const KeypadAdvancedSettings = {
  assets: {
    main: {
      name: "",
    },
    audio: {
      name: "",
    },
    customKeypadImages: [],
    playButtonImage: {
      name: "",
    },
    homeButtonImage: {
      name: "",
    },
    deleteButtonImage: {
      name: "",
    },
    volumeUpButtonImage: {
      name: "",
    },
    volumeDownButtonImage: {
      name: "",
    },
  },
}

export const KeypadCheckForWarnings = (component) => {
  let warnings = []
  if (!component.data.advancedSettings.assets.main.name) {
    warnings.push({
      type: "warning",
      title: `${component.data.title} does not have a background image`,
    })
  }

  console.log("list warnings: ", warnings)

  /// review all assets, e.g. size, bitrate and resolution
  return warnings
}

export const KeypadPlayerLayers = (props) => {
  const itemContext = useContext(ItemContext)

  const history = createBrowserHistory()
  const { state } = history.location

  const {
    item,
    previewMode,
    siblings,
    updateItem,
    openingSnapShot,
    snapshot,
    portrait,
    TourPlayer,
    device,
  } = itemContext

  const onChange = (input) => {
    console.log("Input changed", input)
  }

  const handleInput = (input) => {
    if (input === "d") {
      let tempStr = textValue
      tempStr = tempStr.substring(0, tempStr.length - 1)
      setTextValue(tempStr)
    } else if (input === "e") {
      triggerCode(textValue)
    } else {
      if (textValue.length < 4) {
        setTextValue(textValue + input)
      }
    }
  }
  const triggerCode = (code) => {
    console.log("code", code)
    let componentFound = false
    if (code !== "") {
      siblings.forEach((component) => {
        if (component.data.code !== undefined) {
          if (Number(component.data.code) === Number(code)) {
            updateItem(component, "keypad comp")
            componentFound = true
          }
        }
      })
    }
    if (componentFound === false) {
      setTextValue("")
    }
  }
  const [textValue, setTextValue] = React.useState("")
  const keyButtonBckImage = require("../../../images/keypad/keybutton_bck.png")
  const homeButtonBckImage = require("../../../images/keypad/homebutton.png")
  const playButtonBckImage = require("../../../images/keypad/play_btn.png")
  const deleteButtonBckImage = require("../../../images/keypad/delete_btn.png")
  const volumeUpButtonBckImage = require("../../../images/keypad/volup.png")
  const volumeDownButtonBckImage = require("../../../images/keypad/voldown.png")

  const [bckImage, setBckImage] = React.useState(null)
  const [gettingKeypad, setGettingKeypad] = React.useState(false)
  const [customKeypad, setCustomKeypad] = React.useState(null)

  const indexedDBObj = new DatabaseIndexedDb(item.tourDetails.id)
  console.log("keypad item", item)

  const getKeypadBck = async () => {
    setGettingKeypad(true)
    if (item?.tourDetails?.data?.tourKeypadBck !== "") {
      const keypadPath = `${item.clientId}/${item.projectId}/tourSettings/${item.tourDetails.id}/${item.tourDetails.data.tourKeypadBck}`
      const keypad = await AssetDatabase.getAsset(keypadPath)
      setBckImage(keypad.link)
    }
  }

  const getKeypadElement = async (fileName) => {
    //setGettingKeypad(true)
    return new Promise(async (resolve, reject) => {
      const pathName = `${item.clientId}/${item.projectId}/projectSettings/customKeypad/${fileName}`

      console.log("keypad background: ", pathName)
      const componentImage = await indexedDBObj.fetchImageSrc(fileName)
      console.log("componentImage :>> ", componentImage)
      if (componentImage.length > 0 && componentImage !== undefined) {
        resolve(componentImage[0].backgroundUrl)
      } else {
        const keypad = await AssetDatabase.getAsset(pathName).catch((err) => {
          reject(err)
        })
        resolve(keypad?.link)
      }
    })

    //setBckImage(keypad.link)
  }

  useEffect(() => {
    if (
      item.tourDetails.data.tourKeypadBck !== undefined &&
      DefaultSettings.displayMode === "CMS"
    ) {
      //bckImage = null;
      //setBckImage(null)
      console.log("getKeypadBck")
      getKeypadBck()
    } else {
      const getCache = async () => {
        const componentImage = await indexedDBObj.fetchImageSrc(
          item.tourDetails.data.tourKeypadBck
        )
        console.log("componentImage :>> ", componentImage)
        if (componentImage.length > 0 && componentImage !== undefined) {
          setBckImage(componentImage[0].backgroundUrl)
        } else {
          setBckImage(DefaultSettings.keypadBackground)
        }
      }
      getCache()
    }
  }, [
    item.tourDetails?.data?.tourKeypadBck,
    item.projectDetails.data.customKeypad?.enabled,
  ])
  //console.log('TourPlayer',TourPlayer.height,device.data.screenHeight)

  useEffect(() => {
    const getCustomKeypad = async () => {
      if (item.projectDetails.data.customKeypad?.enabled) {
        //bckImage = null;
        //setBckImage(null)
        let customKeypadSettings = {}
        let customKeypadOptions = [
          "home",
          "fullbackground",
          "deletekey",
          "playkey",
          "num1",
          "num2",
          "num3",
          "num4",
          "num5",
          "num6",
          "num7",
          "num8",
          "num9",
          "num0",
          "volUpkey",
          "volDownkey",
        ]
        for (let index = 0; index < customKeypadOptions.length; index++) {
          const customKey = customKeypadOptions[index]
          //console.log('get keypad asset:', customKey, customKeypadOptions.length)
          if (item.projectDetails.data.customKeypad) {
            if (item.projectDetails.data.customKeypad[customKey]) {
              //console.log('get keypad asset:', item.projectDetails.data.customKeypad[customKey])
              let dropboxElement = await getKeypadElement(
                item.projectDetails.data.customKeypad[customKey]
              )
              //console.log('get keypad asset:', item.projectDetails.data.customKeypad[customKey],dropboxElement)
              if (dropboxElement) {
                customKeypadSettings[customKey] = dropboxElement
                setCustomKeypad({ ...customKeypad, ...customKeypadSettings })
              }
            }
          }
        }

        console.log("setCustomKeypad", customKeypadSettings.home)

        //getKeypadBck()
      }
    }
    getCustomKeypad()
  }, [
    item.projectDetails?.data?.customKeypad,
    item.projectDetails.data.customKeypad?.enabled,
  ])

  //const backGroundColor = '#24292E';
  //const lighterColor = '#323D43';
  const backGroundColor = DefaultSettings.keypadLower
  const lighterColor = DefaultSettings.keypadHigher

  const createKeys = (keys) => {
    let returnKeys = []

    keys.forEach((key) => {
      let icon = key

      if (key === "d") {
        if (item.projectDetails.data.customKeypad?.enabled) {
          // if (customKeypad?.deletekey && textValue.length > 0) {
          //   icon = (
          //     <img
          //       src={customKeypad?.deletekey}
          //       style={{
          //         height: "100%",
          //         width: "100%",
          //         maxWidth: "100%",
          //         objectFit: "fill",
          //         cursor: "pointer",
          //       }}
          //       alt="Ravi test Alt logo"
          //     />
          //   )
          // } else if (customKeypad?.volDownkey) {
          //   icon = (
          //     <img
          //       src={customKeypad?.volDownkey}
          //       style={{
          //         height: "100%",
          //         width: "100%",
          //         maxWidth: "100%",
          //         objectFit: "fill",
          //         cursor: "pointer",
          //       }}
          //       alt="Ravi test Alt logo"
          //     />
          //   )
          // } 
          
          if (customKeypad?.deletekey) {
            icon = (
              <img
                src={customKeypad?.deletekey}
                style={{
                  height: "100%",
                  width: "100%",
                  maxWidth: "100%",
                  objectFit: "fill",
                  cursor: "pointer",
                }}
                alt="Ravi test Alt logo"
              />
            )
          }else {
            icon = null
          }
        } else {
          icon = (
            <img
              // src={
              //   textValue.length > 0
              //     ? deleteButtonBckImage
              //     : volumeDownButtonBckImage
              // }
              src={deleteButtonBckImage}
              style={{
                height: "40%",
                width: "40%",
                maxWidth: "40%",
                objectFit: "contain",
                cursor: "pointer",
              }}
              alt="logo"
            />
          )
        }
        // else if (item.projectDetails.data.customKeypad?.enabled) {

        //   icon = null
        // }
      } else if (key === "e") {
        if (item.projectDetails.data.customKeypad?.enabled) {
          //setShowIcon(true)

          if (customKeypad?.playkey ) {
            icon = (
              <img
                src={customKeypad?.playkey}
                style={{
                  height: "100%",
                  width: "100%",
                  maxWidth: "100%",
                  objectFit: "fill",
                  cursor: "pointer",
                }}
                alt="logo"
              />
            )
          } else {
            icon = null
          }
        } else {
          icon = (
            <img
              // src={
              //   textValue.length > 0
              //     ? playButtonBckImage
              //     : volumeUpButtonBckImage
              // }
              src={playButtonBckImage}
              style={{
                height: "40%",
                width: "40%",
                maxWidth: "40%",
                objectFit: "contain",
                cursor: "pointer",
              }}
              alt="logo"
            />
          )
        }
      } else if (item.projectDetails.data.customKeypad?.enabled) {
        if (customKeypad && customKeypad["num" + key]) {
          icon = (
            <img
              src={customKeypad["num" + key]}
              style={{
                height: "100%",
                width: "100%",
                maxWidth: "100%",
                objectFit: "fill",
                cursor: "pointer",
              }}
              alt="logo"
            />
          )
        } else {
          icon = null
        }
      }
      if (item.projectDetails.data.customKeypad?.enabled) {
        const KeyFunction = ({ icon, keyType }) => {
          console.log("key type:", keyType)
          const [showIcon, setShowIcon] = React.useState(
            keyType === "e" || keyType === "d" ? true : false
          )
          const [hasMouseDown, setHasMouseDown] = React.useState(false)
          return (
            <Grid
              onClick={() => {
                //setShowIcon(true)
                console.log("key pressed:", keyType)
                handleInput(keyType)
              }}
              onMouseDown={(event) => {
                event.preventDefault()
                console.log("mouse down")
                !showIcon && setShowIcon(true)
                setHasMouseDown(true)
              }}
              onMouseUp={() => {
                console.log("mouse up")
                if (keyType !== "e" && keyType !== "d") {
                  setShowIcon(false)
                }
                if (showIcon && hasMouseDown) {
                  handleInput(keyType)
                }

                //setShowIcon(true)
              }}
              onMouseLeave={() => {
                console.log("mouse up")
                if (keyType !== "e" && keyType !== "d") {
                  setShowIcon(false)
                }
                //setShowIcon(true)
              }}
              container
              style={{
                width: TourPlayer.width / 3,
                height: "100%",
                cursor: "pointer",
                justifyContent: "center",
                alignItems: "center",
              }}
              justify="center"
              alignContent="center"
            >
              <div
                onClick={() => {
                  //setShowIcon(true)
                  console.log("key pressed:", keyType)
                  handleInput(keyType)
                }}
                style={{
                  width: "100%",
                  height: "100%",

                  //backgroundColor:'#ff0000aa',
                  //border: `1px solid #ffff00aa`,
                  // cursor: "pointer",
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
              >
                {showIcon ? icon : null}
              </div>
            </Grid>
          )
        }
        returnKeys.push(<KeyFunction icon={icon} keyType={key} />)
      } else {
        returnKeys.push(
          <Grid
            onClick={() => {
              handleInput(key)
            }}
            container
            style={{
              width: TourPlayer.width / 3,
              height: "100%",
              border: `0.5px solid ${backGroundColor}`,
              backgroundImage: `linear-gradient(${lighterColor},${backGroundColor} )`,
              cursor: "pointer",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
            justify="center"
            alignContent="center"
          >
            <h3>{icon}</h3>
          </Grid>
        )
      }
    })
    return returnKeys
  }

  console.log("customKeypad?.fullbackground :>> ", customKeypad?.fullbackground)
  console.log("state", state)

  return (
    <Grid
      container
      style={{
        width: TourPlayer.width,
        height: TourPlayer.height,
        backgroundColor: item.projectDetails.data.customKeypad?.enabled
          ? ""
          : "white",
      }}
    >
      {item.projectDetails.data.customKeypad?.enabled &&
      customKeypad?.fullbackground ? (
        <Grid style={{ position: "absolute", top: 0, left: 0, zIndex: 0 }}>
          <img
            src={customKeypad?.fullbackground}
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
              objectFit: "contain",
              zIndex: 0,
            }}
            alt="logo keynumber"
          />
        </Grid>
      ) : null}

      <Grid
        container
        justify="center"
        alignContent="center"
        style={{
          position: "relative",
          width: TourPlayer.width,
          height: (100 / 1280) * 569 + "%",
          margin: item.projectDetails.data.customKeypad?.enabled
            ? 0
            : TourPlayer.height * 0.01,
          zIndex: 1,
        }}
      >
        {/* {bckImage !== null ? ( */}

        {state ? (
          bckImage !== null && !state.openKeypadSettings ? (
            <img
              src={bckImage}
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
                objectFit: item.projectDetails.data.customKeypad?.enabled
                  ? "fill"
                  : "contain",
              }}
              alt="logo"
            />
          ) : null
        ) : bckImage !== null ? (
          <img
            src={bckImage}
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
              objectFit: item.projectDetails.data.customKeypad?.enabled
                ? "fill"
                : "contain",
            }}
            alt="logo"
          />
        ) : null}
        {/* {bckImage !== null && !state.openKeypadSettings ? (
          <img
            src={bckImage}
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
              objectFit: item.projectDetails.data.customKeypad?.enabled
                ? "fill"
                : "contain",
            }}
            alt="logo"
          />
        ) : null} */}
        {textValue.length > 0 ? (
          <Grid
            key={"input" + textValue}
            container
            style={{
              width: "100%",
              height: item.projectDetails.data.customKeypad?.enabled
                ? 100 - (100 / 569) * 264 + "%"
                : "100%",
              backgroundColor: item.projectDetails.data.customKeypad?.enabled
                ? ""
                : "#44444444",
              position: "absolute",
              top: item.projectDetails.data.customKeypad?.enabled
                ? (100 / 569) * 264 + "%"
                : 0,
              left: 0,
              color: DefaultSettings.keypadTextColor,
              justifyContent: "center",
              alignItems: "center",
            }}
            justify="center"
            alignContent="center"
          >
            <h1
              style={{
                fontSize: (160 / device.data.screenHeight) * TourPlayer.height,
              }}
            >
              {textValue}
            </h1>
          </Grid>
        ) : null}
      </Grid>

      {item.projectDetails.data.customKeypad?.enabled ? (
        <Grid
          style={{
            width: TourPlayer.width,
            height: (100 / 1280) * 164 + "%",
            backgroundColor: item.projectDetails.data.customKeypad?.enabled
              ? ""
              : "white",
            //padding: 10,
            zIndex: 1,
          }}
          justify="center"
          alignContent="center"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            justifySelf="center"
            onClick={() => {
              triggerCode("1234")
            }}
            style={{
              height: (100 / 164) * 94 + "%",
              width: (100 / 720) * 94 + "%",
              marginLeft: (100 / 720) * 313 + "%",
              // marginTop: (100 / 1280) * 56 + "%",
              marginTop: isIOS ? (100 / 1280) * 56 + "%" : "0%",
              objectFit: "contain",
              cursor: "pointer",
            }}
          >
            {customKeypad?.home ? (
              <img
                src={customKeypad?.home}
                style={{
                  height: "100%",
                  width: "100%",
                  flex: 1,
                  objectFit: "fill",
                }}
                alt="logo"
              />
            ) : null}
          </Grid>
        </Grid>
      ) : (
        <Grid
          style={{
            width: TourPlayer.width,
            height: (100 / 1280) * 163 + "%",
            backgroundColor: "white",
            padding: 10,
          }}
          justify="center"
          alignContent="center"
        >
          <img
            src={homeButtonBckImage}
            onClick={() => {
              triggerCode("1234")
            }}
            style={{
              height: "100%",
              width: "20%",
              backgroundColor: backGroundColor,
              borderRadius: 10,
              objectFit: "contain",
              cursor: "pointer",
              padding: "5%",
            }}
            alt="logo"
          />
        </Grid>
      )}

      <Grid
        style={{
          width: TourPlayer.width,
          height: (100 / 1280) * 548 + "%",
          zIndex: 1,
        }}
      >
        <Grid
          container
          style={{ width: TourPlayer.width, height: (100 / 547) * 137 + "%" }}
        >
          {createKeys([1, 2, 3])}
        </Grid>
        <Grid
          container
          style={{ width: TourPlayer.width, height: (100 / 547) * 137 + "%" }}
        >
          {createKeys([4, 5, 6])}
        </Grid>
        <Grid
          container
          style={{ width: TourPlayer.width, height: (100 / 547) * 137 + "%" }}
        >
          {createKeys([7, 8, 9])}
        </Grid>
        <Grid
          container
          style={{ width: TourPlayer.width, height: (100 / 547) * 137 + "%" }}
        >
          {createKeys(["d", 0, "e"])}
        </Grid>
      </Grid>
    </Grid>
  )
}

export const KeypadPlayerOptions = (props) => {
  return null
}

export const KeypadAdvancedSettingsForm = (props) => {
  const itemContext = useContext(ItemContext)
  const {
    item,
    previewMode,
    siblings,
    updateItem,
    device,
    portrait,
    handleAssetsViewerOpen,
  } = itemContext
  const classes = useStyles()
  const [tourKeypadBck, setTourKeypadBck] = useState()
  const [keypadFile, setKeypadFile] = useState(null)
  const [keyPadRequired, setKeyPadRequired] = useState(false)
  const [keyPadUrl, setKeyPadUrl] = useState(false)
  const keyPadFileUploader = useRef(null)
  const [updatingTourSetting, setUpdatingTourSetting] = useState(false)
  const [slicesSettings, setSlicesSettings] = useState({
    listType: "",
    imageHeight: "",
  })
  const [updateKeypadList, setUpdateKeypadList] = useState([])
  const [keypadImageList, setKeypadImageList] = useState([])
  const { advancedSettings } = item.data

  const history = createBrowserHistory()
  const { state } = history.location

  console.log("itemkeypad", item)

  useEffect(() => {
    const checkKeypad = async () => {
      if (item.tourDetails.data.tourKeypadBck && item.data.type === "keypad") {
        setKeyPadRequired(true)
        const keypadBckUrl = `${item.clientId}/${item.projectId}/tourSettings/${
          item.tourDetails.id
        }/${
          keypadFile ? keypadFile?.name : item.tourDetails.data.tourKeypadBck
        }`
        const grabbedtourKeypadBck = await AssetDatabase.getAsset(keypadBckUrl)
        console.log("grabbedtourKeypadBck: ", grabbedtourKeypadBck)
        if (grabbedtourKeypadBck?.link) {
          setTourKeypadBck(grabbedtourKeypadBck.link)
          setKeyPadUrl(grabbedtourKeypadBck.link)
        }
      }
    }
    checkKeypad()
    return () => {}
  }, [keypadFile])

  useEffect(() => {
    const checkKeypadImageListUpdate = async () => {
      if (keypadImageList.length > 0) {
        console.log("keypadImageList :>> ", keypadImageList)
        const finalResut = await AssetDatabase.checkKeypadUploadedFileStatus(
          keypadImageList
        )
        console.log("finalResut :>> ", finalResut)
      }
    }
    checkKeypadImageListUpdate()
  }, [keypadImageList])

  const setTourKeyPadBackground = async (imgFile, toUpdate = false) => {
    if (keyPadRequired || toUpdate) {
      setUpdatingTourSetting(true)

      const keypadFileChange = `${item.clientId}/${item.projectId}/tourSettings/${item.tourDetails.id}`
      await AssetDatabase.createProjectAsset(
        keypadFileChange,
        imgFile,
        imgFile.name
      )
        .then(async (logoRes) => {
          Database.updateTour(
            { clientId: item.clientId, projectId: item.projectId },
            item.tourDetails.id,
            { tourKeypadBck: imgFile.name }
          ).then(() => {
            setUpdatingTourSetting(false)
            setKeypadFile(imgFile)
            item.tourDetails.data.tourKeypadBck = imgFile.name
            updateItem(item)
          })
          if (typeof window !== `undefined`) {
          }
        })
        .catch((err) => console.log(err))
    }
  }
  let projectConfig = { clientId: item.clientId, projectId: item.projectId }

  console.log("item inside keypad :>> ", item, keypadImageList)
  return (
    <Grid>
      {updatingTourSetting ? (
        <Box
          border={1}
          style={{
            borderRadius: 5,
            borderColor: "#ccc",
            margin: 5,
            padding: 5,
          }}
        >
          <p>
            Updating tour settings please do not leave this page until this
            message has gone
          </p>
        </Box>
      ) : null}

      <AutoAssignButton
        onSelection={async (val) => {
          console.log("val, item :>>", val, item)
          let tempCode
          if (val === "keypad") {
            tempCode = ("000" + item.data.code).slice(-4)
          } else {
            tempCode = item.data.reference
          }
          if (val === "reference" || val === "keypad") {
            const assetPresetRequirements = {
              presentMatches: true,
              data: [],
            }
            for (let index = 0; index < 20; index++) {
              //const element = array[index];
              assetPresetRequirements.data.push({
                type: "jpg_png_jpeg",
                title: "Keypad background item " + index,
                index: index,
                code: "kp-btn-touch-down-" + index,
                customKeypadName: `num${index}`,
              })
            }

            assetPresetRequirements.data.push(
              {
                type: "jpg",
                title: "Background Image",
                code: "kp-background",
                customKeypadName: "fullbackground",
                advancedSettingsPath: "main",
              },
              {
                type: "mp3, aac",
                title: "Background Audio",
                code: tempCode + "_00",
                advancedSettingsPath: "audio",
              },
              {
                type: "png_jpg",
                title: "Play Button Image",
                code: "kp-btn-play",
                customKeypadName: "playkey",
                advancedSettingsPath: "playButtonImage",
              },
              {
                type: "png_jpg",
                title: "Home Button Image",
                code: "kp-btn-home",
                customKeypadName: "home",
                advancedSettingsPath: "homeButtonImage",
              },
              {
                type: "png_jpg",
                title: "Delete Button image",
                code: "kp-btn-backspace",
                customKeypadName: "deletekey",
                advancedSettingsPath: "",
              },
              {
                type: "png_jpg",
                title: "Volume Up Button image",
                code: "kp-btn-touch-down-v+",
                customKeypadName: "volUpkey",
                advancedSettingsPath: "volumeUpButtonImage",
              },
              {
                type: "png_jpg",
                title: "Volume Down Button image",
                code: "kp-btn-touch-down-v-",
                customKeypadName: "volDownkey",
                advancedSettingsPath: "volumeDownButtonImage",
              }
            )
            handleAssetsViewerOpen(
              assetPresetRequirements,
              item.data.advancedSettings.assets.main,
              async (meta) => {
                setUpdatingTourSetting(true)
                let valueToCheck = meta.data[2]
                if (meta.data[2] && valueToCheck.match) {
                  // const valueToCheck = meta.data[2]
                  await AssetDatabase.getAsset(
                    valueToCheck.match.dropboxInfo.path_lower
                  )
                    .then(async (res) => {
                      const blobData = await (await fetch(res.link)).blob()

                      const reader = new FileReader()
                      reader.readAsDataURL(blobData) // converts the blob to base64 and calls onload
                      reader.onload = function (evt) {
                        const image = new Image()
                        image.src = reader.result
                        image.onload = function () {
                          setSlicesSettings({
                            ...slicesSettings,
                            imageHeight: image.height,
                          })
                          // handleSliceValue(image.height)
                        }
                      }
                    })
                    .catch(function (error) {
                      console.log(error)
                    })
                }
                const keypadCodeList = []
                const updateKeypadList = []

                const keypadCustomImagePath = `/${item.clientId}/${item.projectId}/projectSettings/customKeypad`

                let KeypadAssetsList

                try {
                  KeypadAssetsList = await dbx.filesListFolder({
                    path: keypadCustomImagePath,
                  })
                  KeypadAssetsList = KeypadAssetsList.entries.map(
                    (item) => item.name
                  )
                } catch (error) {
                  console.log("error", error)
                  KeypadAssetsList = []
                }

                for (let index = 0; index < 30; index++) {
                  const asset = meta.data[index]

                  if (asset.match) {
                    updateKeypadList.push(asset.customKeypadName)
                    const { dropboxInfo, fileInfo } = asset.match
                    const fileType = fileInfo.type.split("/").pop()

                    item.projectDetails.data.customKeypad[
                      asset.customKeypadName
                    ] = `${asset.customKeypadName}.${fileType}`

                    if (asset.index) {
                      item.data.advancedSettings.assets.customKeypadImages[
                        index
                      ] = {
                        index: index,
                        link: "",
                        title: asset.title,
                        assets: {
                          meta: { path_lower: dropboxInfo.path_lower },
                          name: dropboxInfo.name,
                        },
                      }
                    } else {
                      item.data.advancedSettings.assets[
                        asset.advancedSettingsPath
                      ] = {
                        meta: { path_lower: dropboxInfo.path_lower },
                        name: dropboxInfo.name,
                      }
                    }

                    const duplicateKeypadAssets = KeypadAssetsList.filter(
                      (val) => val.includes(asset.customKeypadName)
                    )

                    for (
                      let index = 0;
                      index < duplicateKeypadAssets.length;
                      index++
                    ) {
                      const assetName = duplicateKeypadAssets[index]
                      try {
                        await AssetDatabase.deleteKeypadCodeAsset(
                          `${keypadCustomImagePath}/${assetName}`
                        )
                      } catch (error) {
                        console.log(
                          "error while deleting duplicate keypad assets",
                          error
                        )
                      }
                    }

                    try {
                      const data =
                        await AssetDatabase.uploadKeypadImagesSaveToUrl({
                          from_path: dropboxInfo.path_lower,
                          to_path: `${keypadCustomImagePath}/${asset.customKeypadName}.${fileType}`,
                        })

                      keypadCodeList.push(data)
                    } catch (error) {
                      console.log("error while inseting keypad assets", error)
                    }
                  }
                }

                await Database.updateProject({
                  clientId: item.clientId,
                  projectId: item.projectId,
                  data: {
                    customKeypad: item.projectDetails.data.customKeypad,
                    // customKeypadAssets: advancedSettings.assets,
                  },
                })

                setTimeout(() => {
                  setUpdateKeypadList(updateKeypadList)
                  updateItem(item)
                  setKeypadImageList(keypadCodeList)
                  setUpdatingTourSetting(false)
                }, 1000)

                // // background
                // let bck = assetPresetRequirements.data.find(
                //   (obj) => obj.title === "Background Image"
                // )
                // let bckAudio = assetPresetRequirements.data.find(
                //   (obj) => obj.title === "Background Audio"
                // )
                // let bckPlay = assetPresetRequirements.data.find(
                //   (obj) => obj.title === "Play Button Image"
                // )
                // let bckHome = assetPresetRequirements.data.find(
                //   (obj) => obj.title === "Home Button Image"
                // )
                // let bckDelete = assetPresetRequirements.data.find(
                //   (obj) => obj.title === "Delete Button image"
                // )

                // if (bck?.match) {
                //   const { dropboxInfo } = bck.match
                //   item.data.advancedSettings.assets.main = {
                //     meta: { path_lower: dropboxInfo.path_lower },
                //     name: dropboxInfo.name,
                //   }
                //   item.projectDetails.data.customKeypad["fullbackground"] =
                //     "fullbackground.jpg"
                //   const fileToUpdate = `${keypadCustomImagePath}/fullbackground.jpg`
                //   await AssetDatabase.deleteKeypadCodeAsset(fileToUpdate)
                //     .then(async (res) => {
                //       const data =
                //         await AssetDatabase.uploadKeypadImagesSaveToUrl({
                //           from_path: dropboxInfo.path_lower,
                //           to_path: fileToUpdate,
                //         })
                //       keypadCodeList.push(data)
                //     })
                //     .catch(async (error) => {
                //       const data =
                //         await AssetDatabase.uploadKeypadImagesSaveToUrl({
                //           from_path: dropboxInfo.path_lower,
                //           to_path: fileToUpdate,
                //         })
                //       keypadCodeList.push(data)
                //     })
                // }
                // if (bckAudio?.match) {
                //   const { dropboxInfo } = bckAudio.match
                //   item.data.advancedSettings.assets.audio = {
                //     meta: { path_lower: dropboxInfo.path_lower },
                //     name: dropboxInfo.name,
                //   }
                // }

                // //NOTE:- For play, Home and Delete button settings
                // if (bckPlay?.match) {
                //   console.log(
                //     "asset selected progress background",
                //     bckPlay.match
                //   )
                //   const { dropboxInfo } = bckPlay.match
                //   item.data.advancedSettings.assets.playButtonImage = {
                //     meta: { path_lower: dropboxInfo.path_lower },
                //     name: dropboxInfo.name,
                //   }

                //   item.projectDetails.data.customKeypad["playkey"] =
                //     "playkey.png"
                //   const fileToUpdate = `${keypadCustomImagePath}/playkey.png`
                //   await AssetDatabase.deleteKeypadCodeAsset(fileToUpdate)
                //     .then(async (res) => {
                //       const data =
                //         await AssetDatabase.uploadKeypadImagesSaveToUrl({
                //           from_path: dropboxInfo.path_lower,
                //           to_path: fileToUpdate,
                //         })
                //       keypadCodeList.push(data)
                //     })
                //     .catch(async (error) => {
                //       const data =
                //         await AssetDatabase.uploadKeypadImagesSaveToUrl({
                //           from_path: dropboxInfo.path_lower,
                //           to_path: fileToUpdate,
                //         })
                //       keypadCodeList.push(data)
                //     })
                // }
                // if (bckHome?.match) {
                //   const { dropboxInfo } = bckHome.match
                //   item.data.advancedSettings.assets.homeButtonImage = {
                //     meta: { path_lower: dropboxInfo.path_lower },
                //     name: dropboxInfo.name,
                //   }
                //   item.projectDetails.data.customKeypad["home"] = "home.png"
                //   const fileToUpdate = `${keypadCustomImagePath}/home.png`
                //   await AssetDatabase.deleteKeypadCodeAsset(fileToUpdate)
                //     .then(async (res) => {
                //       const data =
                //         await AssetDatabase.uploadKeypadImagesSaveToUrl({
                //           from_path: dropboxInfo.path_lower,
                //           to_path: fileToUpdate,
                //         })
                //       keypadCodeList.push(data)
                //     })
                //     .catch(async (error) => {
                //       const data =
                //         await AssetDatabase.uploadKeypadImagesSaveToUrl({
                //           from_path: dropboxInfo.path_lower,
                //           to_path: fileToUpdate,
                //         })
                //       keypadCodeList.push(data)
                //     })
                // }
                // if (bckDelete?.match) {
                //   console.log(
                //     "asset selected progress background",
                //     bckDelete.match
                //   )
                //   const { dropboxInfo } = bckDelete.match
                //   item.data.advancedSettings.assets.deleteButtonImage = {
                //     meta: { path_lower: dropboxInfo.path_lower },
                //     name: dropboxInfo.name,
                //   }
                //   item.projectDetails.data.customKeypad["deletekey"] =
                //     "deletekey.png"
                //   const fileToUpdate = `${keypadCustomImagePath}/deletekey.png`
                //   await AssetDatabase.deleteKeypadCodeAsset(fileToUpdate)
                //     .then(async (res) => {
                //       const data =
                //         await AssetDatabase.uploadKeypadImagesSaveToUrl({
                //           from_path: dropboxInfo.path_lower,
                //           to_path: fileToUpdate,
                //         })
                //       keypadCodeList.push(data)
                //     })
                //     .catch(async (error) => {
                //       const data =
                //         await AssetDatabase.uploadKeypadImagesSaveToUrl({
                //           from_path: dropboxInfo.path_lower,
                //           to_path: fileToUpdate,
                //         })
                //       keypadCodeList.push(data)
                //     })
                // }
                // //Note:- Ends here
                // for (let index = 0; index < 100; index++) {
                //   let bck = assetPresetRequirements.data.find(
                //     (obj) => obj.title === "Keypad background item " + index
                //   )
                //   if (bck?.match) {
                //     const { dropboxInfo } = bck.match
                //     item.data.advancedSettings.assets.customKeypadImages.push(
                //       {
                //         index: index,
                //         link: "",
                //         title: bck.title,
                //         assets: {
                //           meta: { path_lower: dropboxInfo.path_lower },
                //           name: dropboxInfo.name,
                //         },
                //       }
                //     )
                //     item.projectDetails.data.customKeypad[
                //       `num${index}`
                //     ] = `num${index}.jpg`
                //     const fileToUpdate = `${keypadCustomImagePath}/num${index}.jpg`
                //     await AssetDatabase.deleteKeypadCodeAsset(fileToUpdate)
                //       .then(async (res) => {
                //         const data =
                //           await AssetDatabase.uploadKeypadImagesSaveToUrl({
                //             from_path: dropboxInfo.path_lower,
                //             to_path: fileToUpdate,
                //           })
                //         keypadCodeList.push(data)
                //       })
                //       .catch(async (error) => {
                //         console.log("error.message :>> ", error.message)
                //         const data =
                //           await AssetDatabase.uploadKeypadImagesSaveToUrl({
                //             from_path: dropboxInfo.path_lower,
                //             to_path: fileToUpdate,
                //           })
                //         keypadCodeList.push(data)
                //       })
                //   }
                // }
                // updateItem(item)
                // setKeypadImageList(keypadCodeList)
                // await Database.updateProject({
                //   clientId: item.clientId,
                //   projectId: item.projectId,
                //   data: {
                //     customKeypad: item.projectDetails.data.customKeypad,
                //     customKeypadAssets: advancedSettings.assets,
                //   },
                // })
                // setUpdatingTourSetting(false)
              },
              previewMode
            )
          }
        }}
      />

      {state.openKeypadSettings ? (
        <>
          <Grid>
            <div style={{ textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.projectDetails.data.customKeypad?.enabled}
                    onChange={(evt) => {
                      if (!item.projectDetails.data.customKeypad) {
                        item.projectDetails.data.customKeypad = {}
                      }
                      item.projectDetails.data.customKeypad.enabled =
                        !item.projectDetails.data.customKeypad?.enabled
                      updateItem(item)
                      Database.updateProject({
                        clientId: item.clientId,
                        projectId: item.projectId,
                        data: {
                          customKeypad: item.projectDetails.data.customKeypad,
                        },
                      })
                    }}
                    value="customKeypadEnabled"
                    color="primary"
                  />
                }
                label="Custom keypad enabled"
              />
            </div>
          </Grid>

          {item.projectDetails.data.customKeypad?.enabled ? (
            <>
              <Box
                border={1}
                style={{
                  borderRadius: 5,
                  borderColor: "#ccc",
                  margin: 5,
                  padding: 5,
                  width: "25%",
                }}
              >
                <KeyPadProjectSetting
                  title="Home button"
                  settingNameUpdated="kp-btn-home"
                  settingName="home"
                  onUpdate={() => {
                    setUpdatingTourSetting(true)
                  }}
                  onComplete={() => {
                    setUpdatingTourSetting(false)
                  }}
                  config={projectConfig}
                  projectDetails={item.projectDetails}
                  assetConfig={advancedSettings.assets}
                  updateKeypadList={updateKeypadList}
                />
                <KeyPadProjectSetting
                  title="Full background"
                  settingNameUpdated="kp-background"
                  settingName="fullbackground"
                  onUpdate={() => {
                    setUpdatingTourSetting(true)
                  }}
                  onComplete={() => {
                    setUpdatingTourSetting(false)
                  }}
                  config={projectConfig}
                  projectDetails={item.projectDetails}
                  assetConfig={advancedSettings.assets}
                  updateKeypadList={updateKeypadList}
                />
              </Box>
              <Box
                border={1}
                style={{
                  borderRadius: 5,
                  borderColor: "#ccc",
                  margin: 5,
                  padding: 5,
                  width: "75%",
                }}
              >
                <Grid container spacing={1}>
                  {/* {item.projectDetails.data?.customKeypadAssets?.customKeypadImages
                .length > 0 ? ( */}
                  <Fragment>
                    <Grid container item spacing={3}>
                      <KeyPadProjectSetting
                        title="Num 1 Over"
                        settingNameUpdated="kp-btn-touch-down-1"
                        settingName="num1"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                      <KeyPadProjectSetting
                        title="Num 2 Over"
                        settingNameUpdated="kp-btn-touch-down-2"
                        settingName="num2"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                      <KeyPadProjectSetting
                        title="Num 3 Over"
                        settingNameUpdated="kp-btn-touch-down-3"
                        settingName="num3"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                    </Grid>
                    <Grid container item spacing={3}>
                      <KeyPadProjectSetting
                        title="Num 4 Over"
                        settingNameUpdated="kp-btn-touch-down-4"
                        settingName="num4"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                      <KeyPadProjectSetting
                        title="Num 5 Over"
                        settingNameUpdated="kp-btn-touch-down-5"
                        settingName="num5"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                      <KeyPadProjectSetting
                        title="Num 6 Over"
                        settingNameUpdated="kp-btn-touch-down-6"
                        settingName="num6"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                    </Grid>
                    <Grid container item spacing={3}>
                      <KeyPadProjectSetting
                        title="Num 7 Over"
                        settingNameUpdated="kp-btn-touch-down-7"
                        settingName="num7"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                      <KeyPadProjectSetting
                        title="Num 8 Over"
                        settingNameUpdated="kp-btn-touch-down-8"
                        settingName="num8"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                      <KeyPadProjectSetting
                        title="Num 9 Over"
                        settingNameUpdated="kp-btn-touch-down-9"
                        settingName="num9"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                    </Grid>
                    <Grid container item spacing={3}>
                      <KeyPadProjectSetting
                        title="Delete button"
                        settingNameUpdated="kp-btn-backspace"
                        settingName="deletekey"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                      <KeyPadProjectSetting
                        title="Num 0 Over"
                        settingNameUpdated="kp-btn-touch-down-0"
                        settingName="num0"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                      <KeyPadProjectSetting
                        title="Play button"
                        settingNameUpdated="kp-btn-play"
                        settingName="playkey"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      spacing={3}
                      justifyContent="space-between"
                    >
                      <KeyPadProjectSetting
                        title="Volume Down button"
                        settingNameUpdated="kp-btn-touch-down-v-"
                        settingName="volDownkey"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                      <KeyPadProjectSetting
                        title="Volume Up button"
                        settingNameUpdated="kp-btn-touch-down-v+"
                        settingName="volUpkey"
                        onUpdate={() => {
                          setUpdatingTourSetting(true)
                        }}
                        onComplete={() => {
                          setUpdatingTourSetting(false)
                        }}
                        config={projectConfig}
                        projectDetails={item.projectDetails}
                        assetConfig={advancedSettings.assets}
                        updateKeypadList={updateKeypadList}
                      />
                    </Grid>
                  </Fragment>
                  {/* ) : (
                <div>Keypad Images are getting updating</div>
              )} */}
                </Grid>
              </Box>
            </>
          ) : null}
        </>
      ) : (
        <Box
          border={1}
          style={{
            borderRadius: 5,
            borderColor: "#ccc",
            margin: 5,
            padding: 5,
            width: "25%",
          }}
        >
          <Grid>
            <div>
              <input
                type="file"
                id="file"
                onChange={(e) => {
                  // setKeyPadRequiredFromKeypadPage(true)
                  setTourKeyPadBackground(e.target.files[0], true)
                }}
                ref={keyPadFileUploader}
                accept="image/*"
                style={{ display: "none" }}
              />
              <img
                src={keyPadUrl}
                style={{
                  width: 100,
                  backgroundColor: "#ccc",
                  justifySelf: "center",
                  alignSelf: "center",
                }}
                alt={keyPadUrl}
              />
              {item.tourDetails.data.tourKeypadBck === undefined ? (
                <Grid className={classes.noImg}>
                  <PhotoSizeSelectActualIcon />
                </Grid>
              ) : null}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    keyPadFileUploader.current.click()
                  }}
                >
                  Keypad background
                </Button>
              </div>
            </div>
          </Grid>
        </Box>
      )}

      {/* <Grid>
        <div style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={item.projectDetails.data.customKeypad?.enabled}
                onChange={(evt) => {
                  if (!item.projectDetails.data.customKeypad) {
                    item.projectDetails.data.customKeypad = {}
                  }
                  item.projectDetails.data.customKeypad.enabled =
                    !item.projectDetails.data.customKeypad?.enabled
                  updateItem(item)
                  Database.updateProject({
                    clientId: item.clientId,
                    projectId: item.projectId,
                    data: {
                      customKeypad: item.projectDetails.data.customKeypad,
                    },
                  })
                }}
                value="customKeypadEnabled"
                color="primary"
              />
            }
            label="Custom keypad enabled"
          />
        </div>
      </Grid> */}

      {/* {item.projectDetails.data.customKeypad?.enabled ? (
        <Grid>
          
          
        </Grid>
      ) : null} */}
    </Grid>
  )
}
export const KeypadBuildBase = (component, buildId) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const generateKeypadFileName = (code) => {
    const ext = component.data.advancedSettings.assets.main.name.substr(
      component.data.advancedSettings.assets.main.name.lastIndexOf(".") + 1
    )
    return code + "_00_" + code + "." + ext
  }
  let filename = generateKeypadFileName(getFourDigitCode(component.code))
  let ent = {
    from_path: `/${component.data.clientId}/${component.data.projectId}/assets/${component.data.advancedSettings.assets.main.name}`,
    to_path: `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`,
  }
  return ent
}

/// Menu data which consists of txt files
export const KeypadBuildData = async (
  component,
  ComponentDetailData,
  buildId
) => {
  const getFourDigitCode = (code) => {
    return ("000" + code).slice(-4)
  }
  const getTwoDigitCode = (code) => {
    return ("00" + code).slice(-2)
  }
  const generateMenuTxtName = (code) => {
    return code + "_00_" + code + ".txt"
  }
  const generateTxtContent = () => {
    let content = ""
    let fourDigit = getFourDigitCode(component.code)
    if (component.data.buttons !== undefined) {
      for (let index = 0; index < component.data.buttons.length; index++) {
        const button = component.data.buttons[index]
        for (
          let blockIndex = 0;
          blockIndex < button.selectedBlocks.length;
          blockIndex++
        ) {
          const block = button.selectedBlocks[blockIndex]

          const link = ComponentDetailData.find((obj) => obj.id === button.link)
          /*const link = {
              code:'0000'
          }*/
          content +=
            fourDigit +
            "_" +
            getTwoDigitCode(block) +
            "_" +
            getFourDigitCode(link.code) +
            "\n"
        }
      }
    }
    return content
  }
  let filename = generateMenuTxtName(getFourDigitCode(component.code))
  let content = generateTxtContent(component, ComponentDetailData)
  let path = `/${component.data.clientId}/${component.data.projectId}/builds/${buildId}/${filename}`
  console.log("sending ---> ", content)
  let result = await AssetDatabase.filesUploadSession(content)
  console.log("commiting ---> ", result)
  let _obj = {
    cursor: {
      session_id: result.session_id,
      offset: content.length,
    },
    commit: {
      path: path,
      mode: "overwrite",
    },
  }
  return [_obj]
}

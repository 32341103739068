class CacheClient {
  // for caching offline
  static clientID = [
    "D6u8wvyJIzZmuYVtfea9",
    "Geel3hwoe7BJIdJCIo03",
    "oE8ninz8jFouIRmAOt6x",
    "VEY8C9FOwm30dSFqDsuU",
    "TcGqvyJRomGddlw4iK7T",
    "gruaitDYMge6DSwJ05Rh",
    "7kHgsjIatkt1pYeuShNC",
    "xIihQuiFqYR2j47nRPr2", // MonLife Heritage
    "TPGjtDYzHKUIYxa8mxi1", // Autoworld Projects
    "3XxDqxGnT16plOzGwysY", // LFC
    "FxpeOFtlE2twBxTmBGiW", // wimbeldon
    "wfxnHQW9uxoYJgoagVKb", // Historic Environment Scotland Projects
    "55J01MEB5hcECFL4RvKp" // Houses Of Parliament
  ];

  // for PWA
  static projectID = [
    "5Wlv5cM9ESyms5EZXjIy", // HES - Striling Castle
    "GlKnhp1Sx0ly9Nx6wrQk", // HES - Urqhart Castle
    "LzjgDk36eL0fK5qOsqJ3", // HES - Forte George
    "M7cdsys6Aoc1mScZSaDk", // HES - Melrose Abbey
    "UaBjnuk01wmJJhZqKPkw", // HES - Jedburg Abbey
    "VonD0tMpPaSxBDE522lM", // HES - Linlithgow
    "ariPJ4RR1LWa1YXxp4Rb", // HES - Doune Castle
    "fM3bl8A8fTlXgwU1rlo1", // HES - St Andrews Castle
    "lmQvAdMCuqfalPbqSzGR", // HES - Edinburgh Castle
    "ltGNu2BMm9heQPvJoZCf", // HES - Glasgow Cathedral
    "nupUp4NBGVjhwy9tMmGC", // HES - Orkney

    // "rjWljy7NR0rSVYr7Bswf", // Houses Of Parliament - HOP IWA
    "55J01MEB5hcECFL4RvKp" // Houses of Parliament
  ];

  static checkClient = clientId =>
    this.clientID.includes(clientId) ? true : false;

  static checkPWA = projectId =>
    this.projectID.includes(projectId) ? true : false;
}

export default CacheClient;

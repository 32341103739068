/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ReactPlayer from "react-player"

import { Header } from "./Header"
import fire from "../../config/firebase"
import Analytics from "../../config/anayltics"
import { db } from "../../IndexedDB/db"
import Dexie from "dexie"
import { v4 as uuidv4 } from "uuid"
import DatabaseIndexedDb from "../../IndexedDB/DatabaseIndexedDb"
// import { useLiveQuery } from "dexie-react-hooks"
// const styles = (theme) => ({
//   root: {
//     flexGrow: 1,
//     position: "relative",
//     width: "100%",
//     minWidth: 300,
//   },
//   button: {
//     margin: theme.spacing(1),
//     backgroundColor: "#B9D949",
//   },
//   paper: {
//     padding: 0,
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   },
//   blocks: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   videoPlayer: {
//     width: 320,
//     height: 180,
//     zIndex: -1,
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   grow: {
//     width: "100%",
//     zIndex: -1,
//     position: "absolute",
//     top: 0,
//     left: 0,
//   },
//   menuButton: {
//     marginLeft: -12,
//     marginRight: 20,
//   },
// })

const TourSelector = (props) => {
  console.log("Props inside TourSelector :>> ", props)
  const { projectInfo, flagUrls, headerUrl, previewDetails } = props.webAppData

  // const indexedFlagDBObj = new DatabaseIndexedDb(projectInfo.projectDetails.id)
  // const flagUrlCache = useLiveQuery(
  //   () => indexedFlagDBObj.db.tours.toArray(),
  //   [props]
  // )
  // console.log("componentImage", componentImage)

  useEffect(() => {
    if (
      localStorage &&
      localStorage.getItem("TBP-userID") &&
      localStorage.getItem("TBP-session-id")
    ) {
      const userID = localStorage.getItem("TBP-userID")
      const session_id = localStorage.getItem("TBP-session-id")
      localStorage.setItem("previousComponentData", null)

      const app_namecustom =
        projectInfo.projectDetails.data.clientName ===
        "Historic Environment Scotland"
          ? "HES"
          : projectInfo.projectDetails.data.clientName

      Analytics.eventTrigger("screen_view", {
        // app_name: projectInfo.projectDetails.data.clientName + "_webapp",
        app_name: app_namecustom + "_webapp",
        screen_name: "Language Selection",
        current_project_name: projectInfo.projectDetails.data.projectName,
        user_id: userID,
        userID: userID,
        session_id: session_id,
        // current_tour_name: tour.data.tourName,
      })
    } else {
      const session_id = uuidv4()
      localStorage.setItem("TBP-session-id", session_id)
      localStorage.setItem("previousComponentData", null)
      const app_namecustom =
        projectInfo.projectDetails.data.clientName ===
        "Historic Environment Scotland"
          ? "HES"
          : projectInfo.projectDetails.data.clientName

      Analytics.eventTrigger("screen_view", {
        // app_name: projectInfo.projectDetails.data.clientName + "_webapp",
        app_name: app_namecustom + "_webapp",
        screen_name: "Language Selection",
        current_project_name: projectInfo.projectDetails.data.projectName,
        session_id: session_id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   if (flagUrlCache?.length === 0) {
  //     getCache()
  //   }
  // }, [flagUrlCache])

  // const getCache = async () => {
  //   let reader = new FileReader()
  //   const fileBlob = await (await fetch(headerUrl)).blob()
  //   reader.readAsDataURL(fileBlob)
  //   console.log("reader", reader)

  //   reader.onload = async function () {
  //     var base64result = reader.result.split(",")[1]
  //     let src = `data:image/png;base64, ${base64result}`
  //     indexedFlagDBObj.addNewTourVideoSrc(src, "headerUrl", {
  //       fileBlob,
  //     })
  //   }

  //   for (let index = 0; index < flagUrls.length; index++) {
  //     let item = flagUrls[index]
  //     console.log("item", item)
  //     let reader = new FileReader()
  //     const fileBlob = await (await fetch(item.url)).blob()
  //     reader.readAsDataURL(fileBlob)
  //     console.log("reader", reader)

  //     reader.onload = async function () {
  //       var base64result = reader.result.split(",")[1]
  //       let src = `data:image/png;base64, ${base64result}`
  //       indexedFlagDBObj.addNewTourVideoSrc(src, item.tourName, {
  //         fileBlob,
  //       })
  //     }
  //   }
  // }

  //if only one tour, skip selection screen and go straight to loading tour
  if (projectInfo.tours.length === 1) {
    languageClick(projectInfo.tours[0])
    return null
  }

  function languageClick(tour) {
    props.loadTour(tour.id)
    console.log("tour inside language Change :>> ", tour)
    let today = new Date().toString()
    props.setDownloadStartLang(today)
    if (localStorage && localStorage.getItem("TBP-userID")) {
      const userID = localStorage.getItem("TBP-userID")
      localStorage.setItem("previousComponentData", null)
      const app_namecustom =
        projectInfo.projectDetails.data.clientName ===
        "Historic Environment Scotland"
          ? "HES"
          : projectInfo.projectDetails.data.clientName

      Analytics.eventTrigger("download_start_lang", {
        // app_name: projectInfo.projectDetails.data.clientName + "_webapp",
        app_name: app_namecustom + "_webapp",
        current_project_name: projectInfo.projectDetails.data.projectName,
        current_tour_name: tour.data.tourName,
        tour_name: tour.data.tourName,
        tour_id: tour.id,
        download_start_time: today,
        user_id: userID,
        userID: userID,
        userType: "new-user",
      })

      localStorage.setItem(
        "TBP-Tour-start-time",
        JSON.stringify({
          // app_name: projectInfo.projectDetails.data.clientName + "_webapp",
          app_name: app_namecustom + "_webapp",
          current_project_name: projectInfo.projectDetails.data.projectName,
          current_tour_name: tour.data.tourName,
          tour_name: tour.data.tourName,
          tour_id: tour.id,
          download_start_time: today,
          user_id: userID,
          userID: userID,
          userType: "new-user",
        })
      )
    } else {
      localStorage.setItem("previousComponentData", null)
      const app_namecustom =
        projectInfo.projectDetails.data.clientName ===
        "Historic Environment Scotland"
          ? "HES"
          : projectInfo.projectDetails.data.clientName

      Analytics.eventTrigger("download_start_lang", {
        // app_name: projectInfo.projectDetails.data.clientName + "_webapp",
        app_name: app_namecustom + "_webapp",
        current_project_name: projectInfo.projectDetails.data.projectName,
        current_tour_name: tour.data.tourName,
        tour_name: tour.data.tourName,
        tour_id: tour.id,
        download_start_time: today,
        userType: "existing",
      })

      localStorage.setItem(
        "TBP-Tour-start-time",
        JSON.stringify({
          // app_name: projectInfo.projectDetails.data.clientName + "_webapp",
          app_name: app_namecustom + "_webapp",
          current_project_name: projectInfo.projectDetails.data.projectName,
          current_tour_name: tour.data.tourName,
          tour_name: tour.data.tourName,
          tour_id: tour.id,
          download_start_time: today,
          userType: "new-user",
        })
      )
    }
    fire.analytics().setUserProperties({
      tour_name: tour.data.tourName,
      //download_started: today,
    })
  }

  const tours = []
  projectInfo.tours.sort((a, b) => (a.data.tourName > b.data.tourName ? 1 : -1))
  flagUrls.sort((a, b) => (a.tourName > b.tourName ? 1 : -1))
  projectInfo.tours.forEach(async (tour, index) => {
    let tourName = tour.data.tourName
    if (tourName.indexOf(".") > 0) {
      tourName = tourName.split(".")[1]
    }
    console.log("flagUrls TourSelector: ", flagUrls)
    console.log("flagUrls not, tour TourSelector: ", tour)
    let flagUrl = flagUrls.find(
      (flagTour) => flagTour.tourName === tour.data.tourName
    )

    // let cacheflagUrl = flagUrlCache?.find(
    //   (flagTour) => flagTour.name === tour.data.tourName
    // )

    /* AssetDatabase.useFirebase =
    projectInfo.projectDetails.data.clientId +
              "/" +
              projectInfo.projectDetails.data.compDetails.projectId +
              "/" +
              projectInfo.projectDetails.data.compDetails.buildId +
              "/" +
              tour.id
            let flagUrl = await AssetDatabase.getAsset(
              tour.data.webApp.tourIcon
            ).catch((error) => {
              console.log("Failed to get flag url ", error)
            })*/
    tours.push(
      <ListItem
        button
        style={{
          justifyContent: "flex-start",
          paddingLeft: "25%",
          backgroundColor: "#EFF0F1",
          borderBottom: "1px solid #dedede",
          height: 57,
          // overflowScrolling: 'touch',
          // WebkitOverflowScrolling: 'touch',
        }}
        onClick={() => {
          languageClick(tour)
        }}
        key={tour.id}
      >
        <img
          style={{ maxHeight: 40 }}
          src={flagUrl?.url}
          alt=""

          // src={cacheflagUrl ? cacheflagUrl.backgroundUrl : flagUrl?.url}
          // alt={cacheflagUrl ? cacheflagUrl.backgroundUrl : flagUrl?.url}
        />

        <ListItemText style={{ flex: "0 auto", paddingLeft: 20 }}>
          {tourName}
        </ListItemText>
      </ListItem>
    )
  })

  return (
    <Grid
      style={{
        backgroundColor: "white",
        margin: "0 auto",
        width: "100vw",
      }}
    >
      {/* <Container style={{ padding: 0 }}> */}
      <Header
        primaryColour={projectInfo.webAppSettings.primary}
        headerUrl={headerUrl}
        cacheEnable={true}
        // headerUrl={
        //   flagUrlCache?.find((item) => item.name === "headerUrl")
        //     ?.backgroundUrl || headerUrl
        // }
      />

      <div
        style={{
          backgroundColor: projectInfo.webAppSettings.secondary,
          color: projectInfo.webAppSettings.textColor,
          fontSize: 26,
          fontWeight: "bold",
          padding: 15,
          paddingTop: 20,
          paddingBottom: 20,
          height: 60,
        }}
      >
        {projectInfo.webAppSettings.selectLanguage}
      </div>
      <Grid
        className="scrollContent tourSelector"
        style={{
          overflow: "auto",
          scrollDirection: "",
          WebkitScrollBar: "",
          width: "100%",
          height: window.innerHeight - 120,
          overflowY: "overlay",
          webkitsc: "touch",
          overflowScrolling: "touch",
          WebkitOverflowScrolling: "touch",
          // maxHeight: "100%",
        }}
      >
        {tours}
        <Button
          onClick={() => {
            caches.keys().then((cacheNames) => {
              cacheNames.forEach((cacheName) => {
                caches.delete(cacheName)
              })
              props.setResetData((prev) => {
                return prev + 1
              })
              console.log("tours :>> ", tours)
              // Deeteing ourbuidler-offline only starts here
              db.delete()
                .then(() => {
                  console.log("Database successfully deleted")
                  // setTimeout (() => {
                  //   new Dexie('tourbuilder-offline');
                  db.open()
                  // }, 300)
                })
                .catch((err) => {
                  console.error("Could not delete database")
                })
                .finally(() => {
                  // Do what should be done next...
                  // new Dexie('tourbuilder-offline');
                })
              // Deeteing ourbuidler-offline only ends here
              projectInfo.tours.forEach(async (tour, index) => {
                Dexie.exists(tour.id)
                  .then(function (exists) {
                    if (exists) {
                      console.log("Database exists")
                      console.log("tour, index :>> ", tour, index)
                      const indexedDBObj = new DatabaseIndexedDb(tour.id)
                      console.log("indexedDBObj :>> :>> ", indexedDBObj)
                      indexedDBObj.db
                        .delete()
                        .then(() => {
                          console.log("Database successfully deleted")
                          indexedDBObj.db.open()
                        })
                        .catch((err) => {
                          console.error("Could not delete database")
                        })
                        .finally(() => {})
                    } else {
                      console.log("Database doesn't exist")
                    }
                  })
                  .catch(function (error) {
                    console.error(
                      "Oops, an error occurred when trying to check database existance"
                    )
                  })
              })

              Dexie.exists(previewDetails.id).then((exists) => {
                if (exists) {
                  const indexedDBObj = new DatabaseIndexedDb(previewDetails.id)
                  indexedDBObj.db
                    .delete()
                    .then(async () => {
                      console.log("Database successfully deleted")
                      // indexedDBObj.db.open()

                      for (
                        let index = 0;
                        index < projectInfo.tours.length;
                        index++
                      ) {
                        const tour = projectInfo.tours[index]
                        const isExist = await Dexie.exists(tour.id)

                        if (isExist) {
                          console.log("Database exists")
                          console.log("tour, index :>> ", tour, index)
                          const indexedDBObj = new DatabaseIndexedDb(tour.id)
                          console.log("indexedDBObj :>> :>> ", indexedDBObj)
                          await indexedDBObj.db.delete()
                        } else {
                          console.log("Database doesn't exist")
                        }
                      }

                      window.location.reload()
                    })
                    .catch((err) => {
                      console.error("Could not delete database")
                    })
                    .finally(() => {})
                } else {
                  console.log("Database doesn't exist")
                }
              })

              console.log("clearing cache")
            })
          }}
          // style={{
          //   color: "#ccc",
          //   padding: 5,
          //   paddingLeft: 50,
          //   paddingRight: 50,
          //   fontWeight: "bold",
          //   fontSize: 14,
          //   marginTop: 20,
          // }}
        >
          {projectInfo.webAppSettings.clearCache}
        </Button>
        <ReactPlayer
          //onReady={handleReady}
          url={require("../../images/silent.mp3")}
          playing={false}
          playsinline={true}
          volume={0}
          width={0}
          height={0}
          style={{
            postion: "absolute",
            display: "none",
            bottom: 0,
          }}
        />
      </Grid>
      {/* </Container> */}
    </Grid>
  )
}

export default TourSelector
